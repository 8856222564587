import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import timeline from 'components/timelineComponent';
import Image from 'components/Image';
import InteractiveTimeline from 'components/InteractiveTimeline';
import SplitView, {viewTypes} from 'templates/SplitView';
import Modal from 'components/ChapterModal';
import {stickyPositions, modalBackgrounds} from 'components/ModalContent';
import {AppSettings} from 'components/Layout';
import ScrollContainer from 'components/ScrollContainer';
import {tabbable} from 'utils/accessibility';

import 'styles/components/standard-interactive-timeline.scss';
import './timeline-pre-1973.scss';

const InfographicPanel = class extends React.Component {
  render() {
    return (
      <div className="infographic">
        <Image
          className="infographic-image"
          image={this.props.image}
          alt="infographic image" width={420} />
        <div>{this.props.children}</div>
      </div>
    );
  }
};

const simpleModal = (headline, content) => {
  return (
    <div className="standard-interactive-timeline__simple-modal">
      <h3 className="uppercase highlight">{headline}</h3>
      <p className="alt" dangerouslySetInnerHTML={{__html: content}} />
    </div>
  );
};

const defaultDateData = [0, 1, 1, 0, 0, 0];
const getDataContents = (images) => {
  return {
    date_1: {
      date: {
        start: new Date(1865, ...defaultDateData),
      },
      headline: '1865',
      subHeadline: 'The 13th Amendment to the Constitution abolishes slavery.',
      content:
        'With the fall of the Confederacy, Radical Republicans support extending the right to vote to black men in the South.',
      rightPanel: <Image className="full-panel-image" showAria image={images.abrahamLincoln} alt="abraham lincoln" sizes="50vw" />,
      modal: simpleModal(
        'Abraham Lincoln',
        'In his final speech in April 1865, President Abraham Lincoln expresses support for extending voting rights to <span class="bold">some</span> black Americans: “the very intelligent” and “those who serve our cause as soldiers.” A few days later, he is assassinated.'
      ),
    },
    date_2: {
      date: {
        start: new Date(1866, ...defaultDateData),
      },
      headline: '1866',
      subHeadline: 'Congress passes the 14th Amendment (ratified in 1868).',
      content:
        'This amendment defines U.S. citizens as all people born or naturalized in the United States (including the formerly enslaved) and declares that states cannot “make or enforce any law which shall abridge the privileges or immunities of citizens of the United States.”',
      rightPanel: (
        <InfographicPanel image={images.infographic_1}>
          <p {...tabbable}>
            In December 1866 only about <span className="highlight">
0.5%</span>{' '}
            of black adult males can vote.
          </p>
        </InfographicPanel>
      ),
      modal: simpleModal(
        'The 14th Amendment',
        'The Amendment guarantees “equal protection of the laws” and threatens states with loss of representation if they fail to grant all male citizens the right to vote.'
      ),
    },
    date_3: {
      date: {
        start: new Date(1867, ...defaultDateData),
      },
      headline: '1867',
      subHeadline: 'Military Reconstruction Act of 1867',
      content:
        'To return to the Union, the former Confederate states have to ratify the 14th Amendment and hold constitutional conventions that will extend the vote to black male citizens.',
      rightPanel: (
        <InfographicPanel image={images.infographic_2}>
          <p {...tabbable}>
            In December 1867 the figure rises to 80.5%, once African American men in the former Confederacy are given the vote.
          </p>
        </InfographicPanel>
      ),
      modal: simpleModal(
        'Military Reconstruction Act of 1867',
        'The first votes are taken after the military helps to register black voters for state constitutional conventions. <br /><br />By the end of 1867, over 80 percent of black male citizens in the former Confederacy are registered.'
      ),
    },
    date_4: {
      date: {
        start: new Date(1868, ...defaultDateData),
      },
      headline: '1868',
      subHeadline:
        'The first national election in which formerly enslaved people in the South can vote.',
      content:
        '500,000 black citizens cast ballots, helping to elect Republican Ulysses S. Grant president.<br/><br/>In South Carolina, a black majority is elected to the state legislature’s lower house.',
      rightPanel: <Image className="panel-image" image={images.image_1868} showAria alt='"The first vote" - Print shows African American men, in dress indicative of their professions, in a queue waiting their turn to vote.' sizes="calc(50vw - 80px)"/>,
    },
    date_5: {
      date: {
        start: new Date(1869, ...defaultDateData),
      },
      headline: '1869',
      subHeadline: 'Congress passes the Fifteenth Amendment (ratified 1870).',
      content:
        'Initially, this amendment secures the right to vote for African American male citizens, and over 2,000 will hold office at every level of government during the Reconstruction era. <br/><br/> But from the beginning, white supremacist groups—including the Ku Klux Klan—work to limit that right, often by intimidation or by violence.',
      rightPanel: <Image className="full-panel-image" image={images.image_1869} showAria alt="African-American election official supervising the first black voters in Washington DC, 1867. Hand-colored woodcut of a 19th-century illustration. (North Wind Picture Archives via AP Images)" sizes="50vw" />,
      modal: simpleModal(
        '15th Amendment',
        '“The right of citizens of the United States to vote shall not be denied or abridged by the United States or by any state on account of race, color, or previous condition of servitude.”'
      ),
    },
    date_6: {
      date: {
        start: new Date(1875, ...defaultDateData),
      },
      headline: '1875',
      subHeadline:
        'In Mississippi, supporters of the Democratic Party⁠—who support white supremacy⁠—use terrorist violence and economic intimidation to regain the state from the control of the multi-racial Republican Party. ',
      content:
        'This “Mississippi Plan” includes the assassination of black political leaders.',
      rightPanel: <Image className="centered-image" showAria image={images.image_1875} alt="Of Course He Wants To Vote The Democratic Ticket (October 1876), Harper's Weekly" sizes="calc(50vw - 80px)" />,
      modal: simpleModal(
        'Henry Adams',
        'Henry Adams, a black Republican, states: “The whole South—every state in the South—had got into the hands of the very men who held us as slaves.” (1877)'
      ),
    },
    date_7: {
      date: {
        start: new Date(1877, ...defaultDateData),
        end: new Date(1890, ...defaultDateData),
      },
      headline: '1877 - 1890',
      subHeadline:
        'African Americans continue to vote throughout the South but in much smaller numbers—especially in the Deep South, largely as a result of violence and intimidation of black voters.',
      content:
        'After 1877, Northern politicians abandon support for African American voting rights in the South. <br /><br />Federal courts uphold southern state laws disfranchising black voters.',
      rightPanel: <Image className="centered-image" image={images.image_1877} showAria alt="Mississippi Ku-Klux in the disguises in which they were captured" sizes="calc(50vw - 80px)" />,
    },
    date_9: {
      date: {
        start: new Date(1890, ...defaultDateData),
        end: new Date(1915, ...defaultDateData),
        giveStartPriority: true,
      },
      headline: '1890 - 1915',
      subHeadline:
        'The low point of African American economic, political, and human rights in the South. Historian Rayford Logan named this era, “The Nadir.”',
      content:
        'The loss of black political power goes hand in hand with the lynching of thousands of African Americans and the rigid segregation of blacks and whites in schools, hospitals, restaurants and other public spaces.</br><br/> After 1890, beginning in Mississippi, Southern states controlled by the Democratic party systematically revise their constitutions to end voting by nearly all African Americans in that region.</br><br/> This marks a shift from overt violence and economic intimidation to institutional and structural disenfranchisement of African Americans.</br><br/> One-party Democratic rule in the South depresses voting by disenfranchising nearly all African Americans and some poor whites. It also gives Southern Democrats dominance in Congress.',
      rightPanel: <Image className="panel-image" image={images.image_1890_1915} alt="lynch mob illustration" sizes="calc(100vw - 80px)"/>,
      modal: (
        <div className="timeline-pre-1973__modal_1890-1915">
          <div className="timeline-pre-1973__modal_1890-1915__content left">
            <div className="timeline-pre-1973__modal_1890-1915__header">
              <h2>Violence & Intimidation</h2>
              <div className="note">
                Between 1876 and 1888, black voter participation falls:
                96% to 26% in South Carolina and 53% to 18% in Georgia
              </div>
            </div>
            <div className="timeline-pre-1973__modal_1890-1915__image">
              <Image image={images.image_1890_1915_modal} className="graph" alt="violence and intimidation chart" width={768} />
            </div>
          </div>
          <div className="timeline-pre-1973__modal_1890-1915__content right">
            <div className="timeline-pre-1973__modal_1890-1915__header">
              <h2>Disenfranchisement</h2>
            </div>
            <p>
              The new laws exclude black voters, and some poor whites. In the
              1904 presidential election, only 29% of all adult males in the
              South voted, compared to 65% in the North.
            </p>
            <div className="timeline-pre-1973__modal_1890-1915__figure">
              <h3>
                <span className="highlight">29%</span> South
              </h3>
              <div className="timeline-pre-1973__modal_1890-1915__image">
                <Image image={images.image_1890_1915_people_1} className="people" width={320} alt="29% males voted in the south" />
              </div>
            </div>
            <div className="timeline-pre-1973__modal_1890-1915__divider">
              <p>Compared to</p>
              <div className="line" />
            </div>
            <div className="timeline-pre-1973__modal_1890-1915__figure">
              <h3>
                <span className="highlight">65%</span> North
              </h3>
              <div className="timeline-pre-1973__modal_1890-1915__image">
                <Image image={images.image_1890_1915_people_2} className="people" width={320} alt="65% males voted in the north" />
              </div>
            </div>
          </div>
        </div>
      ),
    },
    date_10: {
      date: {
        start: new Date(1900, ...defaultDateData),
      },
      headline: '1900',
      subHeadline: 'North Carolina Republican George White leaves Congress.',
      content:
        'He is the last African American to serve in the House until 1929.',
      rightPanel: <Image className="full-panel-image" showAria image={images.image_1900} alt="George Henry White, member of the United States House of Representatives" sizes="50vw" />,
    },
    date_11: {
      date: {
        start: new Date(1920, ...defaultDateData),
      },
      headline: '1920',
      subHeadline: 'The 19th Amendment extends the right to vote to women.',
      content:
        'African American women in the North become active in political campaigns.',
      rightPanel: <Image className="centered-image" image={images.image_1920} showAria alt="Mary Garrity - Ida B. Wells-Barnett - Google Art Project - restoration crop" sizes="50vw" />,
    },
    date_12: {
      date: {
        start: new Date(1890, ...defaultDateData),
        end: new Date(1930, ...defaultDateData),
      },
      headline: '1890 - 1930',
      subHeadline: 'The Great Migration',
      content:
        'Between 1890 and 1930, 1.8 million African Americans move from southern states like Alabama and South Carolina which deny them the vote, to northern states like Illinois and New York that support African American voting rights.',
      rightPanel: <Image className="centered-image" image={images.image_1890_1930} alt="Distribution of the Colored population of the United States: 1890" showAria sizes="calc(50vw - 80px)" />,
    },
    date_13: {
      date: {
        start: new Date(1929, ...defaultDateData),
        end: new Date(1940, ...defaultDateData),
      },
      headline: '1929 - 1940',
      subHeadline: 'The Great Depression and the New Deal',
      content:
        'Black voters in the North switch to the Democratic Party and support the New Deal of President Franklin D. Roosevelt.',
      rightPanel: <Image className="centered-image" image={images.image_1929_1940} alt="8/11/1948-Charleston, SC: Negro voters, for the first time since reconstruction days, went to the polls in South Carolina on August 11 after Supreme Court Decision ruled they could not be deprived of the franchise. In picture, they line up in ward 11, in charleston, awaiting their turn. No incidents were reported however.

      The Tennessee Valley Authority, part of the New Deal, being signed into law in 1933." showAria sizes="calc(50vw - 80px)" />,
    },
    date_14: {
      date: {
        start: new Date(1941, ...defaultDateData),
        end: new Date(1952, ...defaultDateData),
      },
      headline: '1941-1952',
      subHeadline: 'US involvement in World War Two and aftermath',
      content:
        'During World War II, the NAACP launched major voting rights campaigns in the South. <br/><br/> After World War II, black voting rights advocates are attacked—and some are lynched.',
      rightPanel: <Image className="full-panel-image" image={images.image_1941_1952} alt="NAACP leaders with poster NYWTS" showAria sizes="50vw" />,
    },
    date_15: {
      date: {
        start: new Date(1955, ...defaultDateData),
      },
      headline: '1955',
      subHeadline:
        'Voting Rights activists George Lee and Lamar Smith are assassinated in Mississippi.',
      content:
        'The murders are considered a warning to others demanding democratic rights in the region.',
      rightPanel: <Image className="centered-image" image={images.image_1955} alt="Original illustration: George Lee and Lamar Smith" showAria sizes="calc(50vw - 80px)" />,
    },
    date_16: {
      date: {
        start: new Date(1957, ...defaultDateData),
      },
      headline: '1957',
      subHeadline:
        'Congress passes the first Civil Rights Act since Reconstruction.',
      content:
        'It also creates a Civil Rights Commission to investigate voting rights abuses in southern, rural, African American-majority communities.',
      rightPanel: <Image className="centered-image" image={images.image_1957} alt="1957 Civil Rights Act" showAria sizes="calc(50vw - 80px)" />,
    },
  };
};

const TimelinePre1973 = class extends React.Component {
  static contextType = AppSettings;

  constructor(props) {
    super(props);

    this.dateData = getDataContents(props.data);
  }

  componentDidMount() {
    this.context.setTheme('dark');
  }


  renderSlideContent(id) {
    const dateContents = this.dateData;
    return (
      <SplitView
        key={id}
        leftPanelAlignRight
        viewType={viewTypes.EVEN}
        inheritHeight={true}
        leftPanel={
          dateContents[id].leftPanel || (
            <div className="slide-container" {...tabbable}>
              <div className="slide-content">
                <h1>{dateContents[id].headline}</h1>
                <h2>{dateContents[id].subHeadline}</h2>
                <p
                  dangerouslySetInnerHTML={{__html: dateContents[id].content}}
                />
                {dateContents[id].modal &&
                  this.createModal(dateContents[id].modal)}
              </div>
            </div>
          )
        }
        rightPanel={dateContents[id].rightPanel}
      />
    );
  }

  render() {
    const {progress} = this.props;
    const dateContents = this.dateData;

    return (
      <div className="standard-interactive-timeline timeline-pre-1973">
        <InteractiveTimeline
          progress={progress}
          dates={Object.keys(dateContents).map((dateKey) => {
            return {
              label: dateContents[dateKey].headline,
              slug: dateKey,
              date: dateContents[dateKey].date,
              contents: [dateContents[dateKey].content],
              component: this.renderSlideContent(dateKey),
            };
          })}
        />
      </div>
    );
  }

  createModal(children) {
    return (
      <Modal
        className="timeline-pre-1973__modal"
        stickyPosition={stickyPositions.NO_STICKY}
        modalBackground={modalBackgrounds.WHITE}>
        {children}
      </Modal>
    );
  }
};

const query = graphql`
  query {
    abrahamLincoln: file(relativePath: { eq: "pre-1973/img/lincoln@2x.png" }) {
      ...largeImage
    },
    infographic_1: file(relativePath: { eq: "pre-1973/img/infographic_1866@2x.png" }) {
      ...largeImage
    },
    infographic_2: file(relativePath: { eq: "pre-1973/img/infographic_1867@2x.png" }) {
      ...largeImage
    },
    image_1868: file(relativePath: { eq: "pre-1973/img/image_1868@2x.png" }) {
      ...largeImage
    },
    image_1869: file(relativePath: { eq: "pre-1973/img/image_1869@2x.png" }) {
      ...largeImage
    },
    image_1875: file(relativePath: { eq: "pre-1973/img/image_1875@2x.png" }) {
      ...largeImage
    },
    image_1877: file(relativePath: { eq: "pre-1973/img/image_1877@2x.png" }) {
      ...largeImage
    },
    image_1890_1915: file(relativePath: { eq: "pre-1973/img/image_1890_1915@2x.png" }) {
      ...largeImage
    },
    image_1890_1915_modal: file(relativePath: { eq: "pre-1973/img/image_1890_1915_modal@2x.png" }) {
      ...largeImage
    },
    image_1890_1915_people_1: file(relativePath: { eq: "pre-1973/img/image_1890_1915_modal_people_1@2x.png" }) {
      ...largeImage
    },
    image_1890_1915_people_2: file(relativePath: { eq: "pre-1973/img/image_1890_1915_modal_people_2@2x.png" }) {
      ...largeImage
    },
    image_1900: file(relativePath: { eq: "pre-1973/img/image_1900@2x.jpg" }) {
      ...largeImage
    },
    image_1920: file(relativePath: { eq: "pre-1973/img/image_1920@2x.png" }) {
      ...largeImage
    },
    image_1890_1930: file(relativePath: { eq: "pre-1973/img/image_1890_1930@2x.jpg" }) {
      ...largeImage
    },
    image_1929_1940: file(relativePath: { eq: "pre-1973/img/image_1929_1940@2x.png" }) {
      ...largeImage
    },
    image_1941_1952: file(relativePath: { eq: "pre-1973/img/image_1941_1952@2x.png" }) {
      ...largeImage
    },
    image_1955: file(relativePath: { eq: "pre-1973/img/image_1955@2x.jpg" }) {
      ...largeImage
    },
    image_1957: file(relativePath: { eq: "pre-1973/img/image_1957@2x.png" }) {
      ...largeImage
    }
  }
`;

const Component = timeline(TimelinePre1973);
export default (props) => {
  const data = useStaticQuery(query);
  return <Component data={data} {...props} />;
};

import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import timeline from 'components/timelineComponent';
import FullScreenContainer from 'components/FullScreenContainer';
import {getPercentageBetweenBounds} from 'utils';

import kkkSlide from './images/ch1-timeline-kkk.png';

import './chapter-1-scene-4-part-a.scss';

import ImageSwap from 'components/ImageSwap';

const C1S4PA = class extends React.Component {
  render() {
    const {position, data, active} = this.props;
    const {kkkSlide} = data;
    const imageSwapStart = 0;
    const imageSwapEnd = 1;
    const imageSwapProgress = getPercentageBetweenBounds(position, imageSwapStart, imageSwapEnd);
    return (
      <div className="chapter-1-scene-4-part-a">
        <ImageSwap image={kkkSlide} showAria={active} alt="Photograph shows a group of Ku Klux Klan members with cross burning before them. Dated 1948 Photo by: (Universal History Archive/UIG via Getty images)" position={imageSwapProgress} />
      </div>
    );
  }
};

const query = graphql`
  query {
      kkkSlide: file(relativePath: { eq: "chapter-1/scene-4/images/ch1-timeline-kkk.png" }) {
        childImageSharp {
          fluid(maxWidth: 2001) {
            ...selmaFluidImage
          }
        }
      }
  }
`;

export default (props) => {
  const data = useStaticQuery(query);
  return <C1S4PA data={data} {...props} />;
};

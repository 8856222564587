import React from 'react';
import FullScreenContainer from 'components/FullScreenContainer';
import timeline from 'components/timelineComponent';
import HorizontalShift from 'components/HorizontalShift';
import ImageSwap from 'components/ImageSwap';
import TweenMax, {Expo} from 'gsap/TweenMax';
import { ScrollContainerContext } from 'components/ScrollContainer';

import AttrPlugin from 'gsap/AttrPlugin'; // eslint-disable-line no-unused-vars
import CSSPlugin from 'gsap/CSSPlugin'; // eslint-disable-line no-unused-vars
import SplitView, {viewTypes} from 'templates/SplitView';
import {tabbable} from 'utils/accessibility'
import {
  showElemWithAria, hideElementWithAria
} from 'utils/animation';

import './amendments.scss';

import {ordinalSuffixOf} from 'utils';

import plus from './images/plus.svg';

const Amendments = class extends React.Component {
  static contextType = ScrollContainerContext

  constructor(props) {
    super(props);
  }
  componentDidMount() {}

  render() {
    // const {activeAmendments, hoveredAmendments} = this.state;
    const clickableIndexes = [13, 14, 15];
    const {activeAmendments} = this.props

    return (
      <div className="amendments" ref={ref => this.amendments = ref}>
        <div {...tabbable} className="amendments__text" ref={ref => this.amendmentsText = ref}>
          <p>
            <span className="highlight">That right to vote was guaranteed</span>{' '}
            after the Civil War, in the period known as Reconstruction
            (1865-1877).
          </p>
          <p>
            That’s when Congress passed the 13th, 14th, and 15th Amendments to the
            US Constitution.
          </p>
        </div>
        <div ref={(ref) => this.amendmentsGrid = ref}>
          {/* <p className="alt">US Constitution: Pre-1870</p> */}
          <div className="amendments__grid" aria-hidden={true}>
            {Array(15)
              .fill(null)
              .map((_, i) => {
                const index = i + 1;
                const canBeActive = clickableIndexes.includes(index);
                const isActive = activeAmendments && activeAmendments.includes(index);
                // const isHovered = hoveredAmendments.includes(index);
                return (
                  <div
                    className={`amendments__amendment amendment-box-${i + 1} ${
                      canBeActive ? (isActive ? 'active' : 'rectangle') : ''
                    }`}
                    key={`ammendment-${i}`}
                  >
                    <div className="amendments__amendment__container">
                      <div className="amendments__amendment__number">
                        <p>{ordinalSuffixOf(index)}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  }
};

const plusIcon = ( color ) => {
  return (
    <svg
      width="36px"
      height="36px"
      viewBox="0 0 36 36"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
    >
      <g stroke="none" strokeWidth={2} fill="none" fillRule="evenodd">
        <g fill={color}>
          <path
            d="M19,17 L30,17 L30,19 L19,19 L19,30 L17,30 L17,19 L6,19 L6,17 L17,17 L17,6 L19,6 L19,17 Z"
          />
        </g>
      </g>
    </svg>
  );
};

const animation = ({context, timeline}) => {
  const { amendments, amendmentsText, amendmentsGrid } = context

  const { y, height } = amendmentsGrid.getBoundingClientRect()

  TweenMax.set(amendmentsText, {y: 0, ...showElemWithAria})

  const offset = y - ((window.innerHeight - height) / 2) - 10
  timeline.to(amendments, 1.5, {
    y: -offset,
    ease: Expo.easeInOut,
    onStart: () => context.context.disableScroll(),
    onReverseComplete: () => context.context.enableScroll()
  })
  timeline.to(amendmentsText, 0.5, {...hideElementWithAria}, "-=1.5")

}

export default timeline(Amendments, {animation});

import React from 'react';
import {uniqueId} from 'lodash';
import PropTypes from 'prop-types';
import FullScreenContainer from './FullScreenContainer';
import {AppSettings} from 'components/Layout';
import {TimelineMax} from 'gsap';
import {dateDifference, distanceFromDocumentTop} from 'utils';
import {TimelineMarkers} from 'utils/scene'

import scene from 'components/scene'

import 'styles/components/interactive-timeline-normal-view.scss';


const InteractiveTimelineNormalView = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: props.dates[Math.round(props.position * (props.dates.length - 1))].slug,
      allDates: this.parseAllDatesFrom(props.dates),
    };
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    const {registerSceneStops} = this.props.actions
    window.addEventListener('resize', this.handleResize);
    this.handleResize();

    registerSceneStops(
      this.props.dates.map((date, index) => {
        return {
          id: index,
          value: index / (this.props.dates.length - 1)
        }
      })
    )
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  componentWillReceiveProps(nextProps) {
    const serializedDates = JSON.stringify(this.props.dates.map((dateData) => dateData.date));
    const serializedNewDates = JSON.stringify(nextProps.dates.map((dateData) => dateData.date));
    if (serializedDates !== serializedNewDates) {
      const allDates = this.parseAllDatesFrom(nextProps.dates);
      this.setState({allDates});
    }

    const newPosition = Math.round(nextProps.position * (nextProps.dates.length - 1));

    const newActive = nextProps.dates[newPosition];
    if (newActive.slug !== this.state.active) {
      this.setState({
        active: newActive.slug,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    setTimeout(this.positionSpans, 250);
  }

  handleResize() {
    this.positionSpans();
  }

  positionSpans() {
    const spans = document.querySelectorAll('div[data-span-slug]');
    if (spans && spans.length > 0) {
      [].forEach.call(spans, (span, index) => {
        const {spanSlug: slug} = span.dataset;
        const startSelector = `div[data-span-start="${slug}"]`;
        const endSelector = `div[data-span-end="${slug}"]`;
        const startEl = document.querySelector(startSelector);
        const endEl = document.querySelector(endSelector);

        const {left: startLeft} = startEl.getBoundingClientRect();
        const {left: endLeft} = endEl.getBoundingClientRect();
        const width = endLeft - startLeft;

        span.style.width = `${width}px`;
        span.style.left = `${startLeft}px`;
      });
    }
  }

  parseAllDatesFrom(dateData) {
    const existingDates = [];
    const allDates = [];
    const dates = dateData.map((item) => {
      const {date, slug} = item;
      return {date, slug};
    });

    dateData.forEach((item) => {
      const {date, slug} = item;
      const {start, end, giveStartPriority} = date;
      const startTime = start.getTime();
      const alreadyHaveStartDate = existingDates.includes(startTime);

      if (alreadyHaveStartDate) {
        const startIndex = allDates.findIndex((dateData) => dateData.date.getTime() === startTime);
        const startDate = allDates[startIndex];
        startDate.associatedSlugs.push(slug);
        if (giveStartPriority) {
          startDate.slug = slug;
        }
      } else {
        existingDates.push(startTime);
        allDates.push({
          slug,
          associatedSlugs: [slug],
          date: start,
        });
      }

      if (end) {
        const endTime = end.getTime();
        const alreadyHaveEndDate = existingDates.includes(endTime);

        if (alreadyHaveEndDate) {
          const endIndex = allDates.findIndex((dateData) => dateData.date.getTime() === endTime);
          const endDate = allDates[endIndex];
          endDate.associatedSlugs.push(slug);
          endDate.slug = slug;
        } else {
          existingDates.push(endTime);
          allDates.push({
            slug,
            associatedSlugs: [slug],
            date: end,
          });
        }
      }
    });

    // console.log(allDates)
    allDates.sort((a, b) => {
      return a.date - b.date;
    });

    return allDates;
  }

  makeActive(slug) {
    const {dates, actions} = this.props;
    const {jumpToStop} = actions

    const index = dates.findIndex((date) => date.slug === slug);

    jumpToStop(index)
  }

  makeHovered(slug) {
    this.setState({
      hovered: slug,
    });
  }

  renderDates() {
    const {dates} = this.props;
    const {active, hovered, allDates} = this.state;
    const timelineWidth = dateDifference(allDates[0].date, allDates[allDates.length-1].date);
    const activeDate = dates.find((date) => date.slug === active);

    this.spans = [];

    const hasDateRange = (dateData) => {
      return dateData.date.start && dateData.date.end;
    };

    return (
      <div key={`timeline_dates-${this.id}`} className="interactive-timeline-normal-view__dates">
        <div className="interactive-timeline-normal-view__dates__list">
          {allDates.map(({slug, associatedSlugs, date}, index) => {
            let flex;
            let width;
            if (index === allDates.length - 1) {
              flex = '0 0 auto';
            } else {
              const difference = dateDifference(date, allDates[index+1].date);
              const percentage = (difference / timelineWidth) * 100;
              flex = `1 1 ${percentage}%`;
              // width = `${percentage}%`;
            }

            const dateTime = date.getTime();
            const spanStartEls = [];
            const spanEndEls = [];

            associatedSlugs.forEach( (asstSlug) => {
              const dateData = dates.find((dateData) => dateData.slug === asstSlug);
              if (!hasDateRange(dateData)) return;

              const {start, end} = dateData.date;
              const isStartSpan = dateTime === start.getTime();
              const isEndSpan = end && dateTime === end.getTime();

              if (isStartSpan) {
                spanStartEls.push(
                    <div key={`span-start-${asstSlug}`} className="date-span-start" data-span-start={asstSlug} />
                );
              } else if (isEndSpan) {
                spanEndEls.push(
                    <div key={`span-end-${asstSlug}`} className="date-span-end" data-span-end={asstSlug} />
                );
              }
            });

            const activeState = associatedSlugs.includes(active);
            const highlightedState = associatedSlugs.includes(hovered);

            return (
              <div
                className="interactive-timeline-normal-view__dates__list__date"
                key={index}
                style={{flex}}
                onClick={() => this.makeActive(slug)}
                onMouseEnter={() => this.makeHovered(slug)}
                onMouseLeave={() => this.makeHovered(null)}
              >
                <div className="interactive-timeline-normal-view__dates__container">
                  <div className={`interactive-timeline-normal-view__dates__date ${activeState ? 'active' : ''} ${highlightedState ? 'hovered' : ''}`}>
                    <div className="line">
                      {spanStartEls}
                      {spanEndEls}
                    </div>
                    <div className="label">{date.getFullYear()}</div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="interactive-timeline-normal-view__dates__spans">
          {dates.filter((dateData) => hasDateRange(dateData)).map((dateData, index) => {
            const {slug} = dateData;
            return (
              <div
                key={index}
                className={`interactive-timeline-normal-view__dates__spans__span ${slug === active ? 'active' : '' } ${slug === hovered ? 'hovered' : '' }`}
                data-span-slug={slug}
              />
            );
          })}
        </div>
      </div>
    );
  }

  render() {
    const {dates, showUi, title} = this.props;
    const {active} = this.state;
    const ActiveComponent = dates.find(({slug}) => slug === active).component;
    return (
      <div ref={(ref) => this.el = ref} className="interactive-timeline-normal-view">
        {title &&
          <div key={`timeline_title-${this.id}`} className="interactive-timeline-normal-view__title">
            <h1>{title}</h1>
          </div>
        }
        {this.renderDates()}
        {ActiveComponent &&
          <div key={active} className="interactive-timeline-normal-view__component">
            {ActiveComponent}
          </div>
        }
      </div>
    );
  }
};

const InteractiveTimelineNormalViewWrapper = class extends React.Component {
  static contextType = AppSettings

  constructor(props) {
    super(props);
    this.id = uniqueId();
  }

  render() {
    const {dates, progress} = this.props;
    const {screenHeight} = this.context;
    const stops = dates.map((date, index) => {
      return (dates.length / 100) * (index + 1)
    })
    const id = `interactive-timeline-normal-view-component-${this.id}`
    return (
      <FullScreenContainer className="interactive-timeline__content">
        <InteractiveTimelineNormalView position={progress} parentId={id} {...this.props} />
      </FullScreenContainer>
    )
  }
};


InteractiveTimelineNormalView.propTypes = {
  position: PropTypes.number.isRequired,
  title: PropTypes.string,
  dates: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    date: PropTypes.shape({
      start: PropTypes.instanceOf(Date).isRequired,
      end: PropTypes.instanceOf(Date),
    }).isRequired,
    contents: PropTypes.arrayOf(PropTypes.string).isRequired,
    component: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.element,
    ]),
  })).isRequired,
};

export default scene(InteractiveTimelineNormalViewWrapper, {stopAtStart: true});

import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import {TweenMax} from 'gsap';
import {tabbable} from 'utils/accessibility'

import timeline from 'components/timelineComponent';
import Image from 'components/Image';
import HorizontalTimeline from 'components/HorizontalTimeline';

import { getPositionFromValues } from 'utils';
import { showElemWithAria, hideElementWithAria, showElement, hideElement, makeElementTabbable, unmakeElementTabbable } from 'utils/animation';

import './chapter-1-scene-4-part-b.scss';

import Map from './images/map.svg';

const initDate = 1901;
const middleDate = 1929;
const endDate = 1965;
const totalStartTime = middleDate - initDate;
const ratio = 100;

const C1S4PB = class extends React.Component {
  constructor(props) {
    super(props);
    this.congressPeopleData = props.data.congressYaml.congressmen;
  }
  componentDidMount() {
    const {timeline} = this.props;
    timeline.to({placeholder: 0}, totalStartTime / ratio, {placeholder: 1});

    this.congressPeopleData.forEach((person, index) => {
      const personRef = this[`person${index}`];
      TweenMax.set(personRef, {...hideElementWithAria});

      const {startYear, endYear} = person;
      const totalEndYear = endYear >= endDate ? endDate : endYear;

      const initTime = startYear - initDate;
      const endTime = totalEndYear - initDate;
      const tweenTime = totalEndYear - startYear;
      const personTweenTime = 5
      timeline.to(
          personRef,
          personTweenTime / ratio,
          {...showElemWithAria},
          (initTime - personTweenTime) / ratio
      );
      timeline.to(
          {placeholder: 0},
          tweenTime / ratio,
          {placeholder: 1},
          initTime / ratio
      );
      console.log(timeline.duration())

      if (totalEndYear < endDate) {
        timeline.to(personRef, personTweenTime / ratio, {autoAlpha: 0.3}, `-=${personTweenTime / ratio}`);
      }
    });
  }

  getPerson = (person, index, tabIndexes) => {
    const {name, party, officeTime, img} = person;
    const tab = tabIndexes.includes(index) ? tabbable : {}
    return (
      <div
        className="person"
        {...tab}
        ref={(ref) => (this[`person${index}`] = ref)}
        key={`person${index}`}>
        <div className="person__image">
          <Image image={img} alt={`${name} member of the United States House of Representatives.`} size={141} />
          <div className="plus-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
              <path fill="#FFF" fillRule="evenodd" d="M19 17h11v2H19v11h-2V19H6v-2h11V6h2v11z"/>
            </svg>
          </div>
        </div>
        <div className="person__tooltip">
          <div className="caption white">{name}</div>
          <div className="caption gray">{party}</div>
          <div className="caption gray">{officeTime}</div>
        </div>
      </div>
    );
  };

  render() {
    const {timeline, active} = this.props;
    const progress = timeline.progress();
    const filteredCongressPeople = this.congressPeopleData.filter((person) => {
      const currentYear = progress * (endDate - initDate) + initDate;
      // console.log(currentYear, ((person.startYear <= currentYear) && (person.endYear >= currentYear)))
      return ((person.startYear <= currentYear) && (person.endYear >= currentYear));
    });

    const timelineStops = [
      (1929 - initDate) / (endDate - initDate),
      (1935.1 - initDate) / (endDate - initDate),
      (1949 - initDate) / (endDate - initDate)
    ]
    const timelinePosition = getPositionFromValues(timeline.progress(), timelineStops)

    const tabbableIndexes = active ? filteredCongressPeople.map(person => this.congressPeopleData.indexOf(person)) : []
    return (
      <div className="chapter-1-scene-4-part-b">
        <Map className="map" />
        <div className="congressmen">
          {this.congressPeopleData.map((person, index) => this.getPerson(person, index, tabbableIndexes))}
        </div>
        <div className="timeline">
          <HorizontalTimeline
            timeRange={[initDate, middleDate, endDate]}
            position={timelinePosition}
          />
          <p className="congressmen-count alt">
            Number of Congressmen: {filteredCongressPeople.length}
          </p>
        </div>
        <div>
          <span></span>
        </div>
      </div>
    );
  }
};

export const query = graphql`
  query {
    congressYaml(slug:{ eq:"chapter-1-congress"}) {
      congressmen {
        name
        party
        officeTime
        startYear
        endYear
        img {
          childImageSharp {
            fluid(maxWidth: 141) {
              ...selmaFluidImage
            }
          }
        }
      }
    }
  }
`;

const Component = timeline(C1S4PB);
export default (props) => {
  const data = useStaticQuery(query);
  return <Component data={data} {...props} />;
};

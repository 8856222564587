import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import FullScreenContainer from 'components/FullScreenContainer';
import timeline from 'components/timelineComponent';
import Image from 'components/Image';
import ScrollContainer from 'components/ScrollContainer';
import TweenMax from 'gsap';
import {placeholder} from 'utils/animation';
import scene from 'components/scene'
import './chapter-1-scene-6.scss';
import { tabbable } from 'utils/accessibility';

import { showElemWithAria, hideElementWithAria } from 'utils/animation';
import { addTab, removeTab } from '../../../../utils/animation';

const C1S6 = class extends React.Component {
  render() {
    const {
      douglass,
      fDouglasBg,
      fDouglasFg,
      fDouglasArrow,
      struggle1,
      struggle2,
      struggle3,
      struggle4,
      progress1,
      progress2,
      progress3,
      progress4} = this.props.data;

    return (
      <div className="chapter-1-scene-6">
        <FullScreenContainer>
          <div className="chapter-1-scene-6__bg">
            <div className="quote1">
              <Image className="full-width" image={douglass} />
              <div
                className="quote1__container"
                {...tabbable}
                ref={(ref) => (this.quote1 = ref)}>
                <h3 ref={ref => this.q1Text1 = ref}>“The whole history of</h3>
                <h2 ref={ref => this.q1Text2 = ref} className="uppercase">the <span className="progress">progress</span> of human liberty</h2>
                <h3 ref={ref => this.q1Text3 = ref}>shows that all concessions yet made have been</h3>
                <h2 ref={ref => this.q1Text4 = ref} className="uppercase"> born of earnest <span className="red">struggle</span>.</h2>
              </div>
            </div>

            <div className="quote2">
              <div
                ref={(ref) => (this.quote2_container = ref)}
                className="quote2__container">
                <h2 ref={(ref) => (this.quote2_1 = ref)}>
                  If there is <span className="red">no struggle</span>
                </h2>
                <h2 ref={(ref) => (this.quote2_2 = ref)}>
                  there is <span className="white">no progress</span>.”
                </h2>
              </div>
              <div
                className="photo-container"
                ref={(ref) => (this.photoContainer = ref)}>
                <div className="photo" ref={(ref) => (this.struggle1Img = ref)} aria-label='Marchers with SCLC sign for the Savannah Freedom Now Movement, during the March on Washington, 1963' role='img'>
                  <Image image={struggle1} sizes="32vw" alt="" />
                </div>
                <div className="photo" ref={(ref) => (this.struggle2Img = ref)} role='img' aria-label='Sheriff Jim Clark speaking to "Freedom Day" marchers at the Dallas County Courthouse in Selma, Alabama, during a voting rights demonstration.'>
                  <Image image={struggle2} sizes="32vw" alt='' caption="Alabama Department of Archives and History. Donated by Alabama Media Group. Photo by Ed Jones, Birmingham News."/>
                </div>
                <div className="photo" ref={(ref) => (this.struggle3Img = ref)} role='img' aria-label="Photographer Will Counts captures 15-year-old Hazel Bryan's reaction to Elizabeth Eckford during the desegregation of Central High in Little Rock, September 4, 1957.">
                  <Image image={struggle3} sizes="32vw" alt="" />
                </div>
                <div className="photo portrait" ref={(ref) => (this.struggle4Img = ref)} aria-label='Young team supports "Register to Vote" rally with signs.'>
                  <Image image={struggle4} sizes="32vw" alt='' caption="Bob Fitch Photography Archive, Department of Special Collections, Stanford University Libraries"/>
                </div>
                <div className="photo" ref={(ref) => (this.progress1Img = ref)} role='img' aria-label='USA. Montgomery, Alabama. 1965. Civil rights demonstrators rally in Montgomery during the culmination of the Selma March.'>
                  <Image image={progress1} sizes="32vw" alt='' />
                </div>
                <div className="photo" ref={(ref) => (this.progress2Img = ref)} role='img' aria-label='(Original Caption) 11/3/1964-Atlanta, GA: Dr. Martin Luther King votes as his wife Coretta waits her turn.'>
                  <Image image={progress2} sizes="32vw" alt="" />
                </div>
                <div className="photo" ref={(ref) => (this.progress3Img = ref)} aria-label='A picture from racially balanced Leapwood Avenue elementary school, with black, white, oriental & Indian children. (Photo by Ralph Crane/The LIFE Picture Collection/Getty Images)' role='img'>
                  <Image image={progress3} sizes="32vw" alt="" />
                </div>
                <div className="photo" ref={(ref) => (this.progress4Img = ref)} role='img' aria-label='25th January 1972: US Representative Shirley Chisholm of Brooklyn announces her entry for Democratic nomination for the presidency, at the Concord Baptist Church in Brooklyn, New York. Manhattan borough president Percy Sutton applauds at right. (Photo by Don Hogan Charles/New York Times Co./Getty Images)'>
                  <Image image={progress4} sizes="32vw" alt="" />
                </div>
              </div>
            </div>

            <div className="quote3" ref={(ref) => (this.quote3_container = ref)}>
              <div
                className="quote3__bg_cont"
                ref={(ref) => (this.quote3_bg = ref)}>
                  <Image className="quote3__bg" image={fDouglasBg} alt="Frederick Douglass background" sizes="100vw" />
              </div>
              <div
                className="quote3__fg_cont"
                ref={(ref) => (this.quote3_fg = ref)}>
                <Image className="quote3__fg" image={fDouglasFg} alt="Frederick Douglass" sizes="100vw" />
              </div>
              <ScrollContainer alignLeft className="quote3__text__container">
                <div ref={ref=> this.quote3Content = ref} className="quote3__text subhead">
                  <span className="p1" ref={(ref) => (this.quote3_p1 = ref)}>
                    “The whole history of the progress of human liberty shows that
                    all concessions yet made have been born of earnest struggle.
                    If there is no struggle there is no progress.”
                  </span>
                  <br />
                  <br />
                  <span className="white" ref={(ref) => (this.quote3_p2 = ref)}>
                    Those who profess to favor freedom and yet deprecate
                    agitation, are men who want crops without plowing up the
                    ground, they want rain without thunder and lightning. They
                    want the ocean without the awful roar of its many waters.
                    <br />
                    <br />
                    Power concedes nothing without a demand.
                    <br />
                    It never did and it never will.”
                  </span>
                  <br />

                  <div className="quote3__fredrick">
                    <h3 ref={(ref) => (this.quote3_p3 = ref)}>
                      <span style={{whiteSpace: 'nowrap'}}>Frederick Douglass</span>, 1857
                    </h3>
                    <div className="arrow">
                      <div ref={(ref) => (this.quote3_arrow = ref)}>
                        <Image image={fDouglasArrow} sizes="400px" alt="Frederick Douglass arrow" />
                      </div>
                    </div>
                  </div>
                </div>
              </ScrollContainer>
            </div>
          </div>
        </FullScreenContainer>
      </div>
    );
  }
};

const animation = ({context, timeline, easeOut, easeIn}) => {
  const {
    quote1,
    quote2_1,
    quote2_2,
    quote2_container,
    
    struggle1Img,
    struggle4Img,
    struggle3Img,
    struggle2Img,
    progress1Img,
    progress4Img,
    progress3Img,
    progress2Img,
    photoContainer,
    quote3_bg,
    quote3_p1,
    quote3_p2,
    quote3_p3,
    quote3_arrow,
    quote3_fg,
    quote3_container,
    quote3Content,
    q1Text1,
    q1Text2,
    q1Text3,
    q1Text4,
  } = context;

  const {innerHeight} = window;

  TweenMax.set(quote1, {...showElemWithAria});
  TweenMax.set([quote2_container,quote3_container], {display: 'none', ...removeTab});
  TweenMax.set([q1Text1, q1Text2, q1Text3, q1Text4], {...hideElementWithAria, y: 50});
  TweenMax.set([struggle1Img, struggle2Img, struggle3Img, struggle4Img], {y: 300});
  TweenMax.set([progress1Img, progress2Img, progress3Img, progress4Img], {y: 300});

  timeline.to(q1Text1, 1.5, {...showElemWithAria, y: 0, ...easeOut})
  timeline.to(q1Text2, 1.5, {...showElemWithAria, y: 0, ...easeOut}, "-=1.25")
  timeline.to(q1Text3, 1.5, {...showElemWithAria, y: 0, ...easeOut}, "-=1.25")
  timeline.to(q1Text4, 1.5, {...showElemWithAria, y: 0, ...easeOut}, "-=1.25")

  timeline.markers.createTimelineMarker();
  timeline.to(quote1, 1.5, {y: -50, ...hideElementWithAria, ...easeOut });
  
  TweenMax.set(quote2_1, {y: '50'});
  timeline.to(quote2_container, 0.01, {display: 'block', ...addTab  },"-=1.5");
  
  timeline.to(quote2_1, 1.5, {...showElemWithAria, y: 0, ...easeOut}, "-=0.75");
  timeline.to(struggle1Img, 1.5, {...showElemWithAria, ...addTab, y: 0,  ease: Expo.easeInOut}, "-=1.5")
  timeline.to(struggle2Img, 1.5, {...showElemWithAria,  ...addTab, y: 0,  ease: Expo.easeInOut}, "-=1.25")
  timeline.to(struggle3Img, 1.5, {...showElemWithAria,  ...addTab, y: 0,  ease: Expo.easeInOut}, "-=1.25")
  timeline.to(struggle4Img, 1.5, {...showElemWithAria, ...addTab, y: 0,  ease: Expo.easeInOut}, "-=1.25")

  placeholder(timeline, 1);
  timeline.markers.createTimelineMarker()
  TweenMax.set(quote2_container, {...showElemWithAria});
  TweenMax.set(quote2_1, {y: '50'});
  timeline.to([struggle1Img, struggle2Img, struggle3Img, struggle4Img], 0.01,{...removeTab});
  timeline.to(quote2_2, 1, {...showElemWithAria, y: 0, ...easeOut});
  timeline.to([progress1Img, progress2Img, progress3Img, progress4Img], 0.01,{...addTab});
  timeline.to(progress1Img, 1.5, {...showElemWithAria, y: 0, ease: Expo.easeInOut}, "-=1.5")
  timeline.to(progress2Img, 1.5, {...showElemWithAria, y: 0, ease: Expo.easeInOut}, "-=1.25")
  timeline.to(progress3Img, 1.5, {...showElemWithAria, y: 0, ease: Expo.easeInOut}, "-=1.25")
  timeline.to(progress4Img, 1.5, {...showElemWithAria, y: 0, ease: Expo.easeInOut}, "-=1.25")


  timeline.markers.createTimelineMarker()
 timeline.to([progress1Img, progress2Img, progress3Img, progress4Img], 0.01,{...removeTab});
  timeline.to([quote2_1, quote2_2], 1.5, {...hideElementWithAria, y: -50, ease: Expo.easeInOut});
  timeline.to(quote3_container, 0.01, {display: 'block', ...addTab}, "-=1.5");
  timeline.to([quote2_container, quote1], 1.5, {...hideElementWithAria, ease: Expo.easeInOut}, '-=1.5');
  timeline.to(
      photoContainer,
      2,
      {y: -innerHeight + 50, opacity: 0.3, ease: Expo.easeInOut},
      '-=1.5'
  );

  timeline.to(quote3_bg, 1, {...showElemWithAria}, '-=0.75');

  TweenMax.set(quote3Content, {...hideElementWithAria})
  timeline.to(quote3Content, 0.1, {...showElemWithAria}, '-=1');
  timeline.to(quote3_p1, 1, {...showElemWithAria}, '-=1');
  timeline.to(quote3_p2, 1, {...showElemWithAria}, '-=1');
  timeline.to(quote3_fg, 1, {...showElemWithAria, ...addTab}, "-=0.75");

  timeline.to(quote3_p3, 1, {...showElemWithAria, ...easeOut}, '-=0.75');
  timeline.to(quote3_arrow, 1, {...showElemWithAria, ...easeOut}, "-=0.75");

  timeline.markers.createTimelineMarker()

};

export const photoImageFragment = graphql`
  fragment photoImage on File {
    childImageSharp {
      fluid(maxWidth: 556) {
        ...selmaFluidImage
      }
    }
  }
`;

const query = graphql`
  query {
    fDouglasBg: file(relativePath: { eq: "chapter-1/scene-6/img/ch-1-frederick-douglass-bg.png" }) {
      ...largeImage
    }
    fDouglasFg: file(relativePath: { eq: "chapter-1/scene-6/img/ch-1-frederick-douglass-fg-full.png" }) {
      ...mediumImage
    }
    fDouglasArrow: file(relativePath: { eq: "chapter-1/scene-6/img/ch-1-fredrick-douglass-arrow.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...selmaFluidImage
        }
      }
    }
    struggle1: file(relativePath: { eq: "chapter-1/scene-6/img/ch1-image-struggle-01.jpg" }) {
      ...photoImage
    }
    struggle2: file(relativePath: { eq: "chapter-1/scene-6/img/ch1-image-struggle-02.jpg" }) {
      ...photoImage
    }
    struggle3: file(relativePath: { eq: "chapter-1/scene-6/img/ch1-image-struggle-03.jpg" }) {
      ...photoImage
    }
    struggle4: file(relativePath: { eq: "chapter-1/scene-6/img/ch1-image-struggle-04.jpg" }) {
      ...photoImage
    }
    progress1: file(relativePath: { eq: "chapter-1/scene-6/img/ch1-image-struggle-05.jpg" }) {
      ...photoImage
    }
    progress2: file(relativePath: { eq: "chapter-1/scene-6/img/ch1-image-struggle-06.jpg" }) {
      ...photoImage
    }
    progress3: file(relativePath: { eq: "chapter-1/scene-6/img/ch1-image-struggle-07.jpg" }) {
      ...photoImage
    }
    progress4: file(relativePath: { eq: "chapter-1/scene-6/img/ch1-image-struggle-08.jpg" }) {
      ...photoImage
    }
    douglass: file(relativePath: { eq: "chapter-1/scene-6/img/ch-1-douglass-quote-start-bg@2x.jpg" }) {
      ...largeImage
    }
  }
`;

const Component = scene(timeline(C1S6, {animation}));
export default (props) => {
  const data = useStaticQuery(query);
  return <Component data={data} {...props} />;
};

import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';

import Image from 'components/Image';
import FullScreenContainer from 'components/FullScreenContainer';
import FullScreenImage from 'components/FullScreenImage';
import timeline from 'components/timelineComponent';
import {placeholder, hideElement, showElement, hideElementWithAria, showElemWithAria} from 'utils/animation';
import {tabbable} from 'utils/accessibility'

import {Expo, Power1} from 'gsap/TweenMax';
import SplitView from 'templates/SplitView';

;
import scene from 'components/scene';
import './chapter-1-scene-1.scss';

const C1S1 = class extends React.Component {
  render() {
    const {
      map1,
      map2,
      map3,
      doodleCircle,
      dottedArrow,
      doodleBrownsChapel,
      doodleCartersDrug,
      doodleEdmundArrow,
      doodleEdmundCircle,
      doodleEdmundBridge,
      doodleCourthouse,
    } = this.props.data;

    return (
      <div className="chapter-1-scene-1">
        <FullScreenContainer>
          <div className="maps">
            <div
              className="maps__state-maps maps__group"
              ref={(ref) => (this.stateMaps = ref)}
            >
              <div ref={(ref) => (this.map1 = ref)} className="map map-1">
                <Image image={map1} alt="Alabama map in white" sizes="100vw" />
              </div>
              <div ref={(ref) => (this.map2 = ref)} className="map map-2">
                <FullScreenImage
                  image={map2}
                  alignTop
                  alt="Alabama map in black"
                  aspectRatio={8 / 5}
                />
              </div>
            </div>
            <div
              className="maps__street-maps maps__group"
              ref={(ref) => (this.streetMaps = ref)}
            >
              <div ref={(ref) => (this.map3 = ref)} className="map map-3">
                <FullScreenImage
                  image={map3}
                  alt="Selma map"
                  aspectRatio={8 / 5}
                >
                  <ul
                    className="landmarks city-landmarks"
                    ref={(ref) => (this.cityLandmarks = ref)}
                  >
                    <li
                      ref={(ref) => (this.cityLandmark1 = ref)}
                      className="landmarks__landmark browns-chapel"
                    >
                      <p className="secondary">Brown Chapel</p>
                      <div
                        className="landmarks__landmark__image browns"
                        ref={(ref) => (this.brownArrow = ref)}
                      >
                        <Image
                          image={doodleBrownsChapel}
                          width={56}
                          alt="brown chapel"
                        />
                      </div>
                    </li>
                    <li
                      ref={(ref) => (this.cityLandmark2 = ref)}
                      className="landmarks__landmark carter-drug"
                    >
                      <p className="secondary">Carter Drug Co.</p>
                      <div
                        className="landmarks__landmark__image drugstore"
                        ref={(ref) => (this.carterDoodle = ref)}
                      >
                        <Image
                          image={doodleCartersDrug}
                          width={84}
                          alt="Carter's Drugs Co."
                        />
                      </div>
                    </li>
                    <li
                      ref={(ref) => (this.cityLandmark3 = ref)}
                      className="landmarks__landmark edmund-bridge"
                    >
                      <p className="secondary">
                        Edmund Pettus <br />
                        Bridge
                      </p>
                      <div
                        className="landmarks__landmark__image edmund-arrow"
                        ref={(ref) => (this.bridgeArrow = ref)}
                      >
                        <Image
                          image={doodleEdmundArrow}
                          width={32}
                          alt="Edmund Pettus arrow doodle"
                        />
                      </div>
                      <div
                        className="landmarks__landmark__image edmund-circle"
                        ref={(ref) => (this.bridgeCircle = ref)}
                      >
                        <Image
                          image={doodleEdmundCircle}
                          width={187}
                          alt="Edmund Pettus circle doodle"
                        />
                      </div>
                      <div
                        className="landmarks__landmark__image edmund-bridge"
                        ref={(ref) => (this.bridgeDoodle = ref)}
                      >
                        <Image
                          image={doodleEdmundBridge}
                          width={196}
                          alt="Edmund Pettus Bridge doodle"
                        />
                      </div>
                    </li>
                    <li
                      ref={(ref) => (this.cityLandmark4 = ref)}
                      className="landmarks__landmark courthouse"
                    >
                      <p className="secondary">
                        Dallas County <br />
                        Courthouse
                      </p>
                      <div
                        className="landmarks__landmark__image courthouse"
                        ref={(ref) => (this.courthouseArrow = ref)}
                      >
                        <Image
                          image={doodleCourthouse}
                          width={32}
                          alt="Dallas County Courthouse arrow doodle"
                        />
                      </div>
                    </li>
                  </ul>
                </FullScreenImage>
              </div>
            </div>
          </div>
          <div className="text-block intro-title">
            <svg
              className="intro-title__text"
              width="620"
              height="300"
              ref={(ref) => (this.title = ref)}
            >
              <defs>
                <radialGradient
                  id="text-gradient"
                  x1="0"
                  x2="0"
                  y1="0"
                  y2="100%"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop
                    ref={(ref) => (this.stop1 = ref)}
                    stopColor="rgba(0,0,0,1)"
                    offset="0%"
                  />
                  <stop
                    ref={(ref) => (this.stop2 = ref)}
                    stopColor="rgba(0,0,0,1)"
                    offset="100%"
                  />
                </radialGradient>
              </defs>
              <text
                ref={(ref) => (this.introText = ref)}
                fontFamily="Barlow Condensed"
                fontWeight="600"
                fontSize="160"
                x="50%"
                y="50%"
                textAnchor="middle"
                alignmentBaseline="central"
                stroke="black"
                strokeWidth="1px"
                strokeLinecap="butt"
                strokeLinejoin="miter"
                strokeOpacity="1"
                fill="url(#text-gradient)"
              >
                OUR STORY
              </text>
            </svg>
          </div>

          <SplitView
            leftPanel={
              <div className="intro-copy">
                <h2 {...tabbable}>
                  <span ref={(ref) => (this.introLine1 = ref)}>
                    Our story centers on events
                  </span>
                  <span ref={(ref) => (this.introLine2 = ref)}>
                    {' '}
                    in <span className="red">Selma, Alabama</span>, in{' '}
                    <span className="red">1964</span> and{' '}
                    <span className="red">1965</span>.
                  </span>
                </h2>
              </div>
            }
          />

          <div
            ref={(ref) => (this.stateLandmarks = ref)}
            className="state-landmarks-container"
          >
            <FullScreenImage
              image={map2}
              alt="Alabama map in black"
              alignTop
              hideImage
              aspectRatio={8 / 5}
            >
              <ul className="landmarks state-landmarks">
                <li
                  ref={(ref) => (this.stateLandmark2 = ref)}
                  className="landmarks__landmark birmingham"
                >
                  <p className="secondary">Birmingham</p>
                </li>
                <li
                  ref={(ref) => (this.stateLandmark4 = ref)}
                  className="landmarks__landmark baptist"
                >
                  <p className="tertiary">
                    -Sixteenth Street <br />
                    Baptist Church
                  </p>
                </li>
                <li
                  ref={(ref) => (this.stateLandmark1 = ref)}
                  className="landmarks__landmark selma"
                >
                  <p className="primary">Selma</p>
                  <Image
                    className="landmarks__landmark__image  selma-circle"
                    image={doodleCircle}
                    width={196}
                    alt="selma marker"
                  />
                </li>

                <li
                  ref={(ref) => (this.dottedArrow = ref)}
                  className="landmarks__landmark selma-arrow"
                >
                  <div className="landmarks__landmark__image dotted-arrow">
                    <Image image={dottedArrow} width={256} alt="selma arrow" />
                  </div>
                </li>
                <li
                  ref={(ref) => (this.stateLandmark3 = ref)}
                  className="landmarks__landmark montgomery"
                >
                  <p className="secondary">Montgomery</p>
                </li>
                <li
                  ref={(ref) => (this.stateLandmark5 = ref)}
                  className="landmarks__landmark march"
                >
                  <p className="tertiary">
                    1965 <br />
                    March to Montgomery
                  </p>
                </li>
              </ul>
            </FullScreenImage>
          </div>
        </FullScreenContainer>
      </div>
    );
  }
};

const animation = ({context, timeline, easeOut}) => {
  const {
    title,
    introText,
    stop1,
    stop2,
    map1,
    map2,
    map3,
    dottedArrow,
    stateMaps,
    introLine1,
    introLine2,
    stateLandmarks,
    streetMaps,
    stateLandmark1,
    stateLandmark2,
    stateLandmark3,
    stateLandmark4,
    stateLandmark5,
    cityLandmarks,
  } = context;

  TweenMax.set(streetMaps, {z: -250});
  TweenMax.set(title, {z: 0})
  TweenMax.set(stateMaps, {
    z: 0,
    rotationX: 0,
    rotationY: 0,
    rotationZ: 0,
  });

  placeholder(timeline, 0.25);

  TweenMax.set(stop1, {attr: {'stop-color': 'rgba(0,0,0,1)'}});
  TweenMax.set(stop2, {attr: {'stop-color': 'rgba(0,0,0,1)'}});
  TweenMax.set(map1, {...showElemWithAria});
  TweenMax.set(map2, {...hideElementWithAria});
  TweenMax.set(map3, {...hideElementWithAria});
  TweenMax.set(
    [
      introLine1,
      introLine1,
      stateLandmark1,
      stateLandmark2,
      stateLandmark2,
      stateLandmark4,
      stateLandmark3,
      stateLandmark5,
      dottedArrow,
    ],
    {y: 25, ...hideElementWithAria}
  );
  TweenMax.set([introLine2], {...hideElement})
  TweenMax.set(stateLandmarks, {z: 0, ...showElemWithAria});
  TweenMax.set(cityLandmarks, {...hideElementWithAria});

  timeline.to(stop1, 0.25, {
    attr: {'stop-color': 'rgba(0,0,0,0)'},
  });

  timeline.to(
    stop2,
    0.5,
    {attr: {'stop-color': 'rgba(0,0,0,0)'}},
  );

  TweenMax.set(introText, {attr: {'stroke': 'black'}})
  timeline.to(introText, 0.5, {attr: {stroke: 'white'}});
  timeline.to(map1, 0.5, {...hideElementWithAria}, '-=0.25');
  timeline.to(map2, 0.5, {...showElemWithAria}, '-=0.25');
  timeline.to(
    stateMaps,
    2,
    {
      z: 250,
      ease: Expo.easeInOut,
    },
    '-=1'
  );
  timeline.to(title, 2, {z: 750, ease: Expo.easeInOut}, '-=2');

  timeline.to(
    introLine1,
    1.5,
    {...showElemWithAria, y: 0, ...easeOut},
    '-=0.5'
  );

  timeline.to(
    stateLandmark1,
    1.5,
    {
      ...showElemWithAria,
      y: 0,
      ...easeOut,
    },
    '-=1.25'
  );

  timeline.to(
    stateLandmark2,
    1.5,
    {
      ...showElemWithAria,
      y: 0,
      ...easeOut,
    },
    '-=1.25'
  );

  timeline.to(
    stateLandmark4,
    1.5,
    {
      ...showElemWithAria,
      y: 0,
      ...easeOut,
    },
    '-=1.25'
  );

  timeline.to(
    stateLandmark3,
    1.5,
    {
      y: 0,
      ...showElemWithAria,
      ...easeOut,
    },
    '-=1.25'
  );

  timeline.to(
    dottedArrow,
    1.5,
    {
      y: 0,
      ...showElemWithAria,
      ...easeOut,
    },
    '-=1.25'
  );

  timeline.to(
    stateLandmark5,
    1.5,
    {
      ...showElemWithAria,
      y: 0,
      ...easeOut,
    },
    '-=1.25'
  );

  timeline.markers.createTimelineMarker();

  timeline.to(stateMaps, 2.5, {
    z: 1250,
    rotationX: 25,
    rotationY: -7.5,
    rotationZ: 7.5,
    ease: Expo.easeInOut,
  });

  timeline.to(
    stateLandmarks,
    2.5,
    {
      z: 1750,
      ease: Expo.easeInOut,
    },
    '-=2.5'
  );

  timeline.to(
    streetMaps,
    2.5,
    {
      z: 0,
      ease: Expo.easeInOut,
    },
    '-=2.5'
  );

  timeline.to(map3, 0.5, {...showElemWithAria}, '-=1.25');
  timeline.to(map2, 0.5, {...hideElementWithAria}, '-=1.25');
  timeline.to(stateLandmarks, 0.5, {...hideElementWithAria}, '-=0.75');

  timeline.to(
    introLine2,
    1.5,
    {
      ...showElement,
      y: 0,
      ...easeOut,
    },
    '-=0.75'
  );

  timeline.to(
    cityLandmarks,
    0.5,
    {
      ...showElemWithAria,
    },
    '-=1.5'
  );
};

const query = graphql`
  query {
    map1: file(
      relativePath: { eq: "chapter-1/scene-1/images/map-wh-03-AL-a_0.jpg" }
    ) {
      ...mediumImage
    }
    map2: file(
      relativePath: { eq: "chapter-1/scene-1/images/map-bl-01-AL-b.jpg" }
    ) {
      ...extraLargeImage
    }
    map3: file(
      relativePath: { eq: "chapter-1/scene-1/images/map-bl-00-selma-c.jpg" }
    ) {
      ...largeImage
    }
    doodleCircle: file(
      relativePath: { eq: "chapter-1/scene-1/images/doodle-circle@3x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 196) {
          ...selmaFluidImage
        }
      }
    }
    dottedArrow: file(
      relativePath: {
        eq: "chapter-1/scene-1/images/doodle-dotted-arrow@3x.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 266) {
          ...selmaFluidImage
        }
      }
    }
    doodleBrownsChapel: file(
      relativePath: {
        eq: "chapter-1/scene-1/images/doodle-brows-chapel-doodle@3x.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 58) {
          ...selmaFluidImage
        }
      }
    }
    doodleCartersDrug: file(
      relativePath: {
        eq: "chapter-1/scene-1/images/doodle-carter-drug-arrow@3x.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 84) {
          ...selmaFluidImage
        }
      }
    }
    doodleEdmundArrow: file(
      relativePath: {
        eq: "chapter-1/scene-1/images/doodle-edmund-pettus-bridge-arrow@3x.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 32) {
          ...selmaFluidImage
        }
      }
    }
    doodleEdmundCircle: file(
      relativePath: {
        eq: "chapter-1/scene-1/images/doodle-edmund-pettus-bridge-circle@3x.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 187) {
          ...selmaFluidImage
        }
      }
    }
    doodleEdmundBridge: file(
      relativePath: {
        eq: "chapter-1/scene-1/images/doodle-edmund-pettus-bridge-bridge@3x.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 196) {
          ...selmaFluidImage
        }
      }
    }
    doodleCourthouse: file(
      relativePath: {
        eq: "chapter-1/scene-1/images/doodle-dallas-county-courthouse-arrow@3x.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 32) {
          ...selmaFluidImage
        }
      }
    }
  }
`;

const Component = scene(timeline(C1S1, {animation}), {stopAtEnd: true});
export default (props) => {
  const data = useStaticQuery(query);
  return <Component data={data} {...props} />;
};

export const Chapter1Qa = [
  {
    questionNumber: '1.01',
    question: 'Which Amendments are referred to as the Reconstruction Amendments and how did each extend freedom and citizenship to African Americans who had been enslaved?',
    answer: `The 13th, the 14th and the 15th Amendments are referred to as the Reconstruction Amendments.

    The 13th abolishes chattel slavery and says there can be no "involuntary servitude" except as punishment for a crime. The 14th establishes citizenship to anyone born in the United States. It says they are a citizen of both the nation and the state they live in. The 15th gives the right to vote to citizens of the United States and states that THIS right CANNOT be denied on the basis of race, color or previous condition of servitude.`,
  },
  {
    questionNumber: '1.02',
    question: 'What provisions does each Reconstruction Amendment make for enforcement of its guarantees? Who has the power to enforce? How?',
    answer: `Congress was given the power to pass legislation to enforce amendment 13. No specific provisions were included to enforce the definition of citizenship or the limits on state power. Under the supremacy clause, questions about these would be left to the supreme court for Amendment 14. And the 15th is enforced by congress. Congress was given the power to pass legislation to enforce the amendment.`,
  },
  {
    questionNumber: '1.03',
    question: 'What is the relationship between voting and democracy, specifically "majority rule?"',
    answer: `Answers will vary and may include; in order for democracy to be successful, all people have to be able to exercise their right to vote in order to determine "majority rule."`,
  },
  {
    questionNumber: '1.04',
    question: 'When in U.S. history has there been large numbers of Black legislators? What has led to increase(s)? What has led to decrease(s)?',
    answer: `During Reconstruction and after the passage of the 15th amendment, the number of black legislators reached its peak. The number fell sharply after 1877, when federal troops were withdrawn from the South and reconstruction ended. After Jim Crow laws and legal segregation went into effect, the number fell to zero.`,
  },
  {
    questionNumber: '1.05',
    question: 'How does the U.S. constitution guarantee the right to vote for all citizens?',
    answer: 'The 15th Amendment guarantees that the right to vote for all citizens cannot be denied on the basis of race.',
  },
  {
    questionNumber: '1.06',
    question: 'How has race been used to grant and/or restrict the right to vote?',
    answer: `Answers will vary and may include; from slavery to Jim Crow laws and segregation, African American citizenship was denied as a way to restrict the right to vote.`,
  },
  {
    questionNumber: '1.07',
    question: 'Why did voting rights issues emerge after World War II',
    answer: `Answers will vary and may include: activism by the NAACP and other civil rights groups; Great Migration of African Americans to northern states where they could vote; African American participation in World War II boosted demand for equal rights; African American activists were willing to sacrifice their lives for the right to vote. `,
  },
];

import React from 'react';
import PropTypes from 'prop-types';
import ScrollContainer from 'components/ScrollContainer';
import InteractiveTimelineNormalView from 'components/InteractiveTimelineNormalView';
import InteractiveTimelineListView from 'components/InteractiveTimelineListView';

import 'styles/components/interactive-timeline.scss';

const viewTypes = {
  LIST: 'view-type-list',
  NORMAL: 'view-type-normal',
};

const userOptions = {
  VIEW_TYPE: 'viewType',
};

const InteractiveTimeline = class extends React.Component {
  constructor(props) {
    super(props);

    const defaultState = {
      viewType: viewTypes.NORMAL,
      showUi: true,
      userOptions: {},
    }

    const initialState = typeof window !== `undefined` ? this.getViewState() : defaultState
    this.state = initialState;
    this.handleUserOptionSelect = this.handleUserOptionSelect.bind(this);
    this.handleResize = this.handleResize.bind(this);

    this.viewComponents = {
      [viewTypes.LIST]: {
        render: this.renderListView.bind(this),
      },
      [viewTypes.NORMAL]: {
        render: this.renderNormalView.bind(this),
      },
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.viewType !== this.state.viewType) {
      window.scrollTo({top: window.scrollY + this.el.getBoundingClientRect().y});
    }
  }

  handleResize() {
    const newState = this.getViewState(this.props, this.state)
    if (JSON.stringify(newState) !== JSON.stringify(this.state)) {
      this.setState(Object.assign({}, this.state, newState))
    }
  }

  getViewState(props={}, state={}) {
    const {listBreakpoint} = props;
    const {userOptions={}} = state;
    const viewType = userOptions.viewType || viewTypes.NORMAL;
    const breakpoint = listBreakpoint || 800;
    const screenHeightTooSmall = window.innerHeight < 600

    if (window.innerWidth < breakpoint || screenHeightTooSmall) {
      return {viewType: viewTypes.LIST, showUi: false};
    } else {
      return {viewType, showUi: true}
    }
  }

  handleUserOptionSelect(option, value) {
    const newState = {
      [option]: value,
    };

    const userOptions = Object.assign({}, this.state.userOptions, newState);

    this.setState(Object.assign({}, this.state, newState, {userOptions}));
  }

  render() {
    const {title, showUi, dates} = this.props;
    const {viewType} = this.state;

    return (
      <div className="interactive-timeline" ref={(ref) => this.el = ref}>
        {this.viewComponents[viewType].render()}
      </div>
    );
  }

  renderUi() {
    return (
      <div key={`timeline_ui`} className="interactive-timeline__ui">
        <div className="interactive-timeline__ui__group view-options">
          <span>View:</span>
          <ul className="interactive-timeline__ui__group__options">
            <li
              className="interactive-timeline__ui__group__option list-view"
              onClick={() => this.handleUserOptionSelect(userOptions.VIEW_TYPE, viewTypes.LIST)}>
                List
            </li>
            <li
              className="interactive-timeline__ui__group__option component-view"
              onClick={() => this.handleUserOptionSelect(userOptions.VIEW_TYPE, viewTypes.NORMAL)}>
                Normal
            </li>
          </ul>
        </div>
      </div>
    );
  }

  renderListView() {
    const {title, dates, showUi} = this.props;

    return (
      <div className="interactive-timeline__content">
        {showUi && this.state.showUi && this.renderUi()}
        <InteractiveTimelineListView dates={dates} title={title} />
      </div>
    );
  }

  renderNormalView() {
    const {title, dates, showUi, progress, onDateSelected} = this.props;
    const {screenHeight} = this.context;

    return (
      <div>
        {showUi && this.state.showUi && this.renderUi()}
        <InteractiveTimelineNormalView dates={dates} title={title} onDateSelected={onDateSelected} progress={progress}/>
      </div>
    );
  }
};

InteractiveTimeline.propTypes = {
  showUi: PropTypes.bool,
  title: PropTypes.string,
  listBreakpoint: PropTypes.bool,
  dates: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    date: PropTypes.shape({
      start: PropTypes.instanceOf(Date).isRequired,
      end: PropTypes.instanceOf(Date),
    }).isRequired,
    contents: PropTypes.arrayOf(PropTypes.string).isRequired,
    component: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.element,
    ]),
  })).isRequired,
};

export default InteractiveTimeline;

import React from 'react';
import PropTypes from 'prop-types';
import timeline from 'components/timelineComponent';
import FullScreenContainer from 'components/FullScreenContainer';

import {TweenMax} from 'gsap';
import {Expo, Power1} from 'gsap/TweenMax';

import 'styles/components/horizontal-shift.scss';

const HorizontalShift = class extends React.Component {
  constructor(props) {
    super(props);
    this.createTween = this.createTween.bind(this);
  }
  componentDidMount() {
    const {timeline, screens} = this.props;

    window.addEventListener('resize', this.createTween);
    requestAnimationFrame( () => this.createTween());
  }

  componentWillReceiveProps({position}) {
    if (this.shiftTween) {
      this.shiftTween.seek(position * this.shiftTween.duration());
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.createTween);
  }

  createTween() {
    const {position} = this.props;
    const windowWidth = window.innerWidth;
    const containerWidth = this.screens.getBoundingClientRect().width;
    this.tweenAmount = (containerWidth - windowWidth);
    this.shiftTween = TweenMax.to(this.screens, 1, {x: -this.tweenAmount, ease: Expo.easeInOut});
    this.shiftTween.pause();
    this.shiftTween.seek(position);
  }

  render() {
    const {screens} = this.props;
    return (
      <FullScreenContainer>
        <div className="horizontal-shift" >
          <div className="horizontal-shift__screens" ref={(ref) => this.screens = ref}>
            {screens.map((screen, index) =>
              <FullScreenContainer key={index}>
                {screen}
              </FullScreenContainer>
            )}
          </div>
        </div>
      </FullScreenContainer>
    );
  }
};

HorizontalShift.propTypes = {
  screens: PropTypes.arrayOf(PropTypes.node).isRequired,
  position: PropTypes.number.isRequired,
};

export default HorizontalShift;

import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import FullScreenContainer from 'components/FullScreenContainer';
import timeline from 'components/timelineComponent';
import Image from 'components/Image';
import HorizontalShift from 'components/HorizontalShift';
import ImageSwap from 'components/ImageSwap';
import Modal from 'components/ChapterModal';
import {stickyPositions} from 'components/ModalContent';
import Amendments from './Amendments';

import TweenMax, {Expo} from 'gsap/TweenMax';
import AttrPlugin from 'gsap/AttrPlugin'; // eslint-disable-line no-unused-vars
import CSSPlugin from 'gsap/CSSPlugin'; // eslint-disable-line no-unused-vars
import SplitView, {viewTypes} from 'templates/SplitView';
import AmendmentModal from './AmendmentModal';
import {tabbable, untabbable} from 'utils/accessibility';

import {getPercentageBetweenBounds} from 'utils';
import {
  placeholder, hideAllRefs, showElemWithAria, hideElementWithAria, showElement, hideElement, makeElementTabbable, unmakeElementTabbable,
} from 'utils/animation';

import scene from 'components/scene';

import './chapter-1-scene-2.scss';

const duration = 10.25;
const imageSwapStart = 0/duration;
const imageSwapEnd = 1/duration;
const sceneShiftStart = 1/duration;
const sceneShiftEnd = 3/duration;
const amendmentShiftStart = 4/duration;
const amendmentShiftEnd = 5.75/duration;

const C1S2 = class extends React.Component {
  render() {
    const {position} = this.props;
    const imageSwapProgress = getPercentageBetweenBounds(
      position,
      imageSwapStart,
      imageSwapEnd
    );
    const splitViewProgress = getPercentageBetweenBounds(
      position,
      sceneShiftStart,
      sceneShiftEnd
    );

    const amendmentsProgress = getPercentageBetweenBounds(
      position,
      amendmentShiftStart,
      amendmentShiftEnd
    );

    const {court, courtRed, constitution1, constitution2} = this.props.data;

    return (
      <div className="chapter-1-scene-2">
        <FullScreenContainer>
          <HorizontalShift
            position={splitViewProgress}
            screens={[
              <SplitView
                className="court-scene"
                viewType={viewTypes.EVEN}
                fullWidthPanel={
                  <FullScreenContainer>
                    <ImageSwap
                      image={court}
                      image2={courtRed}
                      image1ClassName="court-scene__image"
                      alt="USA. Selma, Alabama. October 7, 1964. Freedom Day. Police arrest demonstrators from the Student Nonviolent Coordinating Committee (SNCC) for holding placards urging blacks to register to vote in front of the Selma federal building."
                      showAria={true}
                      position={imageSwapProgress}
                    />
                    <div
                      className="black-gradient"
                      ref="gradient"
                    />
                  </FullScreenContainer>
                }
                rightPanel={
                  <div ref="courtText">
                    <CourtText position={splitViewProgress} />
                  </div>
                }
              />,
              <SplitView
                viewType={viewTypes.EVEN}
                fullWidthPanel={
                  <div>
                    <div
                      className="chapter-1-scene-2__half-image"
                      ref="constitution1">
                      <Image
                        image={constitution1}
                        alt="constitution-1 image"
                        sizes="50vw"
                        height="auto"
                        width="50%"
                      />
                    </div>

                    <div
                      className="chapter-1-scene-2__half-image"
                      ref="constitution2">
                      <Image
                        image={constitution2}
                        alt="constitution-2 image"
                        sizes="50vw"
                        height="auto"
                        width="50%"
                      />
                    </div>
                  </div>
                }
                leftPanel={
                  <div
                    className="amendments-text"
                    {...tabbable}
                    ref="amendmentsText">
                    <h3 className="uppercase">
                      These Amendments served{' '}
                      <span className="highlight">three</span> main purposes:
                    </h3>
                    <ul>
                      <li ref="amendment1">
                        To establish the terms under which the seceded states of
                        the Confederacy would return to the Union.
                      </li>
                      <li ref="amendment2">
                        To assure freedom and full citizenship to African
                        Americans who had been held in slavery.
                      </li>
                      <li ref="amendment3">
                        To bar racial discrimination in voting for African
                        American men (American women did not gain the right to
                        vote until 1920).
                      </li>
                    </ul>
                    <div ref="amendmentModal">
                      <Modal
                        className="chapter-1-scene-2__modal"
                        stickyPosition={stickyPositions.NO_STICKY}>
                        <AmendmentModal />
                      </Modal>
                    </div>
                  </div>
                }
                rightPanel={
                  <div ref="amendments">
                    <Amendments
                      position={amendmentsProgress}
                    />
                  </div>
                }
              />,
            ]}
          />
        </FullScreenContainer>
      </div>
    );
  }
};

const CourtText = timeline(
  class extends React.Component {
    componentDidMount() {
      const {timeline} = this.props;
      timeline.to(this.courtText, sceneShiftEnd - sceneShiftStart, {
        x: window.innerWidth / 2,
        ease: Expo.easeInOut,
      });
    }
    render() {
      return (
        <div
          className="court-text"
          ref={(ref) => (this.courtText = ref)}>
          <p>
            Like many communities in the deep south, in Selma, state laws —
            <span className="highlight">
              and the threat of economic and physical intimidation
            </span>
            — prevented African Americans from exercising their right to vote.
          </p>
        </div>
      );
    }
  }
);

const query = graphql`
  query {
    court: file(
      relativePath: { eq: "chapter-1/scene-2/images/ch-1-image-3-a@2x.jpg" }
    ) {
      ...largeImage
    }
    courtRed: file(
      relativePath: { eq: "chapter-1/scene-2/images/ch-1-image-3-b@2x.jpg" }
    ) {
      ...largeImage
    }
    constitution1: file(
      relativePath: {
        eq: "chapter-1/scene-2/images/ch-1-image-alabama-constitution@2x.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1350) {
          ...selmaFluidImage
        }
      }
    }
    constitution2: file(
      relativePath: {
        eq: "chapter-1/scene-2/images/ch-1-image-amendment@2x.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1432) {
          ...selmaFluidImage
        }
      }
    }
  }
`;

const animation = ({context, timeline, easeOut, goingBackwards}) => {
  const {
    gradient,
    courtText,
    courtTextHighlight,
    constitution1,
    constitution2,
    amendments,
    amendmentsText,
    amendment1,
    amendment2,
    amendment3,
    amendmentModal,
  } = context.refs;

  hideAllRefs(context.refs, [
    'courtTextHighlight',
    'amendment1',
    'amendment2',
    'amendment3',
  ]);

  TweenMax.set([
    amendment1,
    amendment2,
    amendment3], {...hideElement});

  const amendmentBox1 = '.amendment-box-13';
  const amendmentBox2 = '.amendment-box-14';
  const amendmentBox3 = '.amendment-box-15';
  const courtImage = '.image-container__image.full-res .court-scene__image';

  timeline.set([courtText, courtImage], {...makeElementTabbable});
  timeline.to(courtText, 1, {...showElemWithAria});
  timeline.to(gradient, 1, {...showElement}, `-=1`); // 1 second
  timeline.markers.createTimelineMarker();
  timeline.to(courtText, 0.01, {...unmakeElementTabbable});
  timeline.to(courtImage, 0.01, {...unmakeElementTabbable}, '-=0.01');
  timeline.set(constitution1, {...showElement});
  placeholder(timeline, 1.99); // 2 seconds
  timeline.to(constitution1, 0.5, {...hideElementWithAria});
  timeline.to(constitution2, 0.5, {autoAlpha: 0.25}, '-=0.5');
  timeline.to(amendments, 0.5, {...showElemWithAria}); // 5 seconds

  timeline.markers.createTimelineMarker();

  timeline.to(constitution2, 0.5, {...hideElementWithAria});
  timeline.to(courtText, 1.5, {...hideElementWithAria, y: -50, ease: Expo.easeInOut}, '-=0.5');
  // console.log(timeline.duration());
  timeline.set(amendmentsText, {y: 50});
  timeline.to(amendmentsText, 1.5, {...showElemWithAria, y: 0, ...easeOut}, '-=0.75');
  timeline.set(amendmentsText, {...makeElementTabbable});

  TweenMax.set([amendment1, amendment2, amendment3, amendmentModal], {y: 30});
  // console.log(timeline.duration());
  timeline.to(amendment1, 1.5, {className: '+=active', ...showElement, y: 0, ...easeOut}, '-=0.5'); // 6 seconds
  timeline.to(amendmentBox1, `${goingBackwards} ? 0 : 1.5`, {className: '+=active'}, `-=${goingBackwards ? 0 : 1.5}`);

  // console.log(timeline.duration());
  timeline.to(amendment2, 1.5, {className: '+=active', ...showElement, y: 0, ...easeOut}); // 7 seconds
  timeline.to(amendmentBox2, `${goingBackwards} ? 0 : 1.5`, {className: '+=active'}, `-=${goingBackwards ? 0 : 1.5}`);
  // console.log(timeline.duration());
  timeline.to(amendment3, 1.5, {className: '+=active', ...showElement, y: 0, ...easeOut}); // 8 seconds
  timeline.to(amendmentBox3, `${goingBackwards} ? 0 : 1.5`, {className: '+=active'}, `-=${goingBackwards ? 0 : 1.5}`);
  timeline.to(amendmentModal, 1.5, {...showElemWithAria, y: 0, ...easeOut}, '-=1.5');
};

const Component = scene(timeline(C1S2, {animation}, {stopAtEnd: true}));
export default (props) => {
  const data = useStaticQuery(query);
  return <Component data={data} {...props} />;
};

import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import VerticalTimeline from 'components/VerticalTimeline';
import PartA from './Chapter1Scene4PartA';
import PartB from './Chapter1Scene4PartB';
import scene from 'components/scene'

const C1S4PA = class extends React.Component {
  componentDidMount () {
    const { registerSceneStops } = this.props.actions
    const offset = .0569444
    registerSceneStops([
      { id: 0, value: (1/9)},
      { id: 1, value: (2/9)},
      { id: 2, value: (3/9) + ((4/9) * ((1929 - 1901) / (1965 - 1901)))},
      { id: 3, value: (3/9) + ((4/9) * ((1935.1 - 1901) / (1965 - 1901)))},
      { id: 4, value: (3/9) + ((4/9) * ((1949 - 1901) / (1965 - 1901)))},
      { id: 5, value: (7/9)},
      { id: 6, value: (8/9)},
      { id: 7, value: 1},
    ])
  }

  render() {
    const {position, data, actions} = this.props;
    const { registerSceneStops } = actions

    return (
      <VerticalTimeline progress={position} timelineData={data.timelinesYaml.dates} registerSceneStops={registerSceneStops}>
        <PartA slideId={'event-1877'}></PartA>
        <PartB slideId={'event-1901'}></PartB>
      </VerticalTimeline>
    );
  }
};

export const query = graphql`
  query {
    timelinesYaml(slug:{ eq:"chapter-1-vertical-timeline"}) {
      dates {
        slideId
        h1
        subhead
        p
        imgAttribution
        isFullBleed
        delay
        alt
        img {
          ...mediumImage
        }
      }
    }
  }
`;

const Component = scene(C1S4PA, {duration: 16});
export default (props) => {
  const data = useStaticQuery(query);
  return <Component data={data} {...props} />;
};

import React from 'react';
import PropTypes from 'prop-types';
import ScrollContainer from 'components/ScrollContainer'
import scene from 'components/scene'

import 'styles/components/interactive-timeline-list-view.scss';

const InteractiveTimelineListView = class extends React.Component {
  render() {
    const {dates} = this.props;

    return (
      <ScrollContainer className="interactive-timeline-list-view">
        <div className="interactive-timeline-list-view__list">
          <div className="interactive-timeline-list-view__list__item">
            <div className="interactive-timeline-list-view__list__item__header" />
            <div className="interactive-timeline-list-view__list__item__line" />
            <div className="interactive-timeline-list-view__list__item__contents" />
          </div>

          {dates.map(({label, contents}, index) => {
            return (
              <div key={index} className="interactive-timeline-list-view__list__item">
                <div className="interactive-timeline-list-view__list__item__header">
                  <h2>{label}</h2>
                </div>
                <div className="interactive-timeline-list-view__list__item__line" />
                <div className="interactive-timeline-list-view__list__item__contents">
                  {contents.map((content, index) => <p key={index} dangerouslySetInnerHTML={{__html: content}} />)}
                </div>
              </div>
            );
          })}
        </div>
      </ScrollContainer>
    );
  }
};

InteractiveTimelineListView.propTypes = {
  title: PropTypes.string,
  dates: PropTypes.array
};

export default scene(InteractiveTimelineListView);

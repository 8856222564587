import React from 'react';

import './map-modal.scss';

import FullMap from './imgs/svg/full-map.svg';
import MapModalInfo from './MapModalInfo';

const MapModal = class extends React.Component {
  state = {
    currentStateIndex: 0,
  }

  componentDidMount() {
    this.statesGroup = document.querySelectorAll('.full-map g');
    this.statesGroup.forEach((element, index) => {
      index === this.state.currentStateIndex
        && element.classList.add('selected');
      element.addEventListener(
          'click', this.stateClicked = () => this.onStateClick(element, index)
      );
    });
  }

  componentWillUnmount() {
    this.statesGroup.forEach((element) => {
      element.removeEventListener('click', this.stateClicked);
    });
  }

  onStateClick = (element, index) => {
    this.setState({currentStateIndex: index});

    this.statesGroup.forEach((elem) => elem.classList.remove('selected'));
    element.classList.add('selected');
  }

  render() {
    const {currentStateIndex} = this.state;
    const selectedState = MapModalInfo[currentStateIndex];
    const {state, congressmen, officeholders} = selectedState;

    return (
      <div className="map-modal">
        <div className="map-modal__info">
          <h3 className="title">{state}</h3>
          <h5>AFRICAN AMERICANS ELECTED TO CONGRESS</h5>
          <div className="congressmen">
            {
              congressmen.map((val, index) => (
                <div className="subhead" key={`cm${index}`}>
                  {val}
                </div>
              ))
            }
          </div>
          <h5>TOTAL OFFICEHOLDERS</h5>
          <div className="subhead">
            {officeholders}
          </div>
        </div>
        <div className="map-modal__map">
          <FullMap className="full-map"/>
        </div>
      </div>
    );
  }
};

export default MapModal;

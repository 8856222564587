import React from 'react';
import FullScreenContainer from './FullScreenContainer';
import ScrollContainer from './ScrollContainer';
import PropTypes from 'prop-types';
import {TimelineMax, TweenMax} from 'gsap';
import Image from './Image';
import {tabbable} from 'utils/accessibility'

import '../styles/components/vertical-timeline.scss';
import { showElemWithAria, hideElementWithAria } from 'utils/animation';

const VerticalTimeline = class extends React.Component {
  constructor(props) {
    super(props)
    this.state={
      active: 0
    }
  }
  componentWillMount() {
    const {timelineData} = this.props;
    this.eventTimes = timelineData.map(() => 0);
  }

  componentDidMount() {
    this.tlTween = new TimelineMax({});
    this.tlTween.pause();

    this.contentTween = new TimelineMax({});
    this.contentTween.pause();

    requestAnimationFrame( () => {
      this.buildTimeline(
          this.tlTween,
          '#vertical-list',
          '.time-event__scroll-container'
      );
      const timeSum = this.buildTimeline(
          this.contentTween,
          '.vertical-timeline__timeline-image',
          '.timeline-img-container',
          true
      );
      console.log(timeSum)
    })
  }

  componentWillReceiveProps({progress}) {
    this.tlTween.progress(progress);
    this.contentTween.progress(progress);
  }

  componentWillUnmount() {
    this.tlTween.kill();
    this.contentTween.kill();
  }

  buildTimeline(timeline, containerClass, elementClass, updateFrames = false) {
    const listPositions = this.calculateListPositions(elementClass, containerClass);
    const firstElementPosition = listPositions[0];
    const {timelineData} = this.props;
    const scrollTime = 1;
    const timeSum = [];
    timelineData.forEach((val, ind) => {
      const y = (listPositions[ind] - firstElementPosition) * -1;
      const {delay} = val;
      const onUpdate = this.onFrameUpdate.bind(this);
      const timelineSum = this.sumDurations(timeSum);

      const onUpdateParams = [
        ind,
        delay,
        timelineSum,
      ];
      const fromParams = updateFrames ? {onUpdate,
        onUpdateParams} : {};
      timeSum[ind] = delay + scrollTime;

      const imgClass = `${containerClass} ${elementClass} .image-src-${ind}`;
      const imgExists = updateFrames &&
        document.body.contains(document.querySelector(imgClass));

      if (updateFrames && imgExists) {
        const elemParams = ind === 0 ? showElemWithAria : hideElementWithAria
        TweenMax.set(imgClass, {...elemParams});
      }

      const callback = (newIndex) => {
        if (this.state.active !== newIndex) {
          this.setState({
            active: newIndex
          })
        }
      }

      if (ind === 0) {
        timeline.from(containerClass, delay, fromParams);
      } else {
        // console.log(scrollTime)
        const callbacks = {onComplete: () => callback(ind), onReverseComplete: () => callback(ind - 1)}
        timeline.to(containerClass, scrollTime, {y, ease:Expo.easeInOut, ...callbacks});
        if (updateFrames && imgExists) {
          timeline.to(imgClass, scrollTime, {...showElemWithAria}, `-=1`);
        }
        timeline.from(containerClass, delay, fromParams);
      }
    });

    return timeSum

  }

  sumDurations(durations) {
    return durations.reduce((a, b) => a + b, 0);
  }

  onFrameUpdate(frameIndex, delay, timelineSum) {
    const delayTime = this.contentTween.time() - timelineSum;
    this.currentFrameIndex = frameIndex;
    const sceneProgress = delay > 0 ? delayTime / delay : delay;
    this.eventTimes[frameIndex] = sceneProgress < 0 ? 0 : sceneProgress;
  }

  calculateListPositions(elemName, containerClass) {
    const listElems = document.querySelectorAll(elemName);
    const container = document.querySelector(containerClass);
    const { y:containerY } = container.getBoundingClientRect()
    let listPositions = [];
    listElems.forEach((elem) => {
      const { y:itemY } = elem.getBoundingClientRect()
      listPositions = [
        ...listPositions,
        itemY - containerY,
      ];

      if (this.props.shouldLog) {
        const line = document.createElement('div')
        line.style = `height: 1px; width: 100%; position: absolute; top: ${itemY - containerY}px; left: 0; background-color: red;`
        container.appendChild(line)
      }
    });
    return listPositions;
  }

  getTimelineContent(element, key, canTab) {
    const {img, h1, slideId, imgAttribution, alt} = element;
    const position = this.eventTimes[key] || 0;
    let comp;
    const {children} = this.props;
    React.Children.forEach(children, (child, index) => {
      if (child.props.slideId === slideId) {
        comp = React.cloneElement(child, {active: key === this.state.active, key,
          position});
      }
    });

    return comp ||
    <Image
      className={`timeline-image-fill image-src-${key}`}
      image={img} alt={alt ? alt : h1} showAria={alt && canTab ? true : false} sizes="50vw" caption={imgAttribution}
    />;
  }

  render() {
    const {timelineData} = this.props;
    return (
      <FullScreenContainer>
        <div className="vertical-timeline">
          <div className="vertical-timeline__timeline-image">
            {
              timelineData.map((element, index) => {
                const {isFullBleed} = element;
                const fullBleedClass = !isFullBleed ? 'left-aligned' : '';
                return (
                  <div
                    key={`evt-image-${index}`}
                    className={`timeline-img-container ${fullBleedClass}`}
                   
                  >
                    {
                      this.getTimelineContent(element, index, index === this.state.active)
                    }
                  </div>
                );
              })
            }
          </div>
          <div className="vertical-timeline__historic-timeline">
            <ul
              id="vertical-list"
              className="vertical-list"
            >
              {
                timelineData.map((element, index) => {
                  const {h1, subhead, p} = element;
                  const canTab = index === this.state.active ? tabbable : {}
                  return (
                    <li
                      key={`evt-${index}`}
                      className="time-event"
                    >
                      <ScrollContainer className='time-event__scroll-container'>
                        <div className="time-event__content" {...canTab}>
                          <h1>
                            {h1}
                          </h1>
                          <div className="subhead">
                            {subhead}
                          </div>
                          <p className="alt">
                            {p}
                          </p>
                        </div>
                      </ScrollContainer>
                    </li>
                  );
                })
              }
            </ul>
          </div>
        </div>
      </FullScreenContainer>
    );
  }
};

VerticalTimeline.propTypes = {
  progress: PropTypes.number.isRequired,
  timelineData: PropTypes.array.isRequired,
};

export default VerticalTimeline;

import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import {TweenMax} from 'gsap';
import timeline from 'components/timelineComponent';
import FullScreenContainer from 'components/FullScreenContainer';
import ScrollContainer from 'components/ScrollContainer';
import Image from 'components/Image';
import ImageSwap from 'components/ImageSwap';
import {AppSettings} from 'components/Layout';
import {getPercentageBetweenBounds, getPositionFromValues} from 'utils';
import {tabbable} from 'utils/accessibility'

import {
  placeholder, easeInOutCubic, showElemWithAria, hideElementWithAria
} from 'utils/animation';
import scene from 'components/scene'
import VideoAnimation from 'components/VideoAnimation';

import './chapter-1-scene-3.scss';

import HorizontalTimeline from 'components/HorizontalTimeline';
import Modal from 'components/ChapterModal';
import {stickyPositions} from 'components/ModalContent';
import MapModal from './MapModal';

import infographic from './video/infographic.mp4'

const duration = 3.5
const map1SwapStart = (0);
const map2SwapStart = (1.5/duration);
const videoSection2Start = map2SwapStart + 0.01;

const C1S3 = class extends React.Component {
  static contextType = AppSettings

  render() {
    const {position, data} = this.props;

    const { poster } = data;

    // const videoProgress = getPercentageBetweenBounds(position, 0, 1)
    const timelineProgress = getPositionFromValues(position, [map2SwapStart], [0.12])
    return (
      <div className="chapter-1-scene-3">
        <FullScreenContainer>
          <div className="chapter-1-scene-3__map">
            <VideoAnimation video={infographic} sections={[
              {
                breakpoints: [0, videoSection2Start],
                keyframes: [0, 0.4]
              },
              {
                breakpoints: [videoSection2Start, 1],
                keyframes: [0.4, 1]
              }
            ]} progress={position} />
            <div className="chapter-1-scene-3__poster">
              <div ref={ref => this.poster = ref}>
                <Image image={poster} />
              </div>
            </div>
          </div>

          <div className="chapter-1-scene-3__content" ref={(ref) => this.paragraph1 = ref}>
            <ScrollContainer alignLeft>
              <div {...tabbable} className="chapter-1-scene-3__content__text">
                <div className="subhead">
                  During <span className="highlight">Reconstruction</span>, African Americans voted in large numbers, and democratic institutions become more representative of the US as a whole—
                </div>
                <p className="alt">
                  Except in some western states, most women could not vote until 1920.
                </p>
              </div>
            </ScrollContainer>
          </div>

          <div className="chapter-1-scene-3__content" ref={(ref) => this.paragraph2 = ref}>
            <ScrollContainer alignLeft>
              <div {...tabbable} className="chapter-1-scene-3__content__text">
                <div className="subhead">
                  But while the Reconstruction amendments were designed to expand democracy to the freedmen, that goal was <span className="highlight">challenged</span> in the South by the Democratic Party.
                </div>
                <p className="alt">
                The southern Democrats were led by former Confederates and others who wished to restore “states rights” and <span className="highlight">confine African Americans to second-class citizenship</span>.
                </p>
              </div>
            </ScrollContainer>
          </div>

          <div className="chapter-1-scene-3__timeline">
            <HorizontalTimeline
              timeRange={[1865, 1877, 1965]}
              timeRangeLabels={['End of US Civil War', 'Reconstruction', 'Jim Crow Era']}
              position={timelineProgress}/>
          </div>
        </FullScreenContainer>
      </div>
    );
  }
};

const query = graphql`
  query {
      map: file(relativePath: { eq: "chapter-1/scene-3/imgs/ch-1-gag-reconstruction-map-fpo-a@2x_full.png" }) {
      ...largeImage
    }
    poster: file(
      relativePath: {
        eq: "chapter-1/scene-3/imgs/ch-1-gag-reconstruction-poster-white-mans-country@2x.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1158) {
          ...selmaFluidImage
        }
      }
    },
  }
`;

const animation = ({timeline, context, easeOut}) => {
  const {screenHeight} = context.context;

  const modalRef = '.chapter-1-scene-3__modal'

  TweenMax.set(context.paragraph1, {y: screenHeight, transformOrigin: '50% 0', ...hideElementWithAria});
  TweenMax.set(context.paragraph2, {y: screenHeight, transformOrigin: '50% 0', ...hideElementWithAria});
  TweenMax.set(modalRef, {...hideElementWithAria});
  TweenMax.set(context.poster, {...hideElementWithAria, y: 50});

  timeline.to(context.paragraph1, 1.5, {y: '0%', ...showElemWithAria, ...easeOut});
  timeline.to(modalRef, 1, {...
    showElemWithAria}, '-=1');

  timeline.markers.createTimelineMarker(true)

  timeline.to(context.paragraph1, 2, {y: '-100%', ...hideElementWithAria, ease: Expo.easeInOut});
  timeline.to(modalRef, 1, {...hideElementWithAria}, `-=${1}`);
  timeline.to(context.paragraph2, 1.5, {y: '0%', ...showElemWithAria, ease: Expo.easeInOut}, "-=1.5");
  // console.log(timeline.duration())
  timeline.to(context.poster, 1.5, {y: 0, ...showElemWithAria, ...easeOut}, "-=1.5");
  // console.log(timeline.duration())
}

const Component = scene(timeline(C1S3, {animation}), {stopAtEnd: true});
export default (props) => {
  const data = useStaticQuery(query);
  return <Component data={data} {...props} />;
};

const MapModalInfo = [
  {
    state: 'Texas',
    congressmen: ['Jeremiah Haralson', 'James T. Rapier', 'Benjamin S. Turner'],
    officeholders: 173,
  },
  {
    state: 'Alabama',
    congressmen: ['Jeremiah Haralson', 'James T. Rapier', 'Benjamin S. Turner'],
    officeholders: 173,
  },
  {
    state: 'Florida',
    congressmen: ['Jeremiah Haralson', 'James T. Rapier', 'Benjamin S. Turner'],
    officeholders: 173,
  },
  {
    state: 'Georgia',
    congressmen: ['Richard H. Cain', 'Robert C. De Large', 'Robert B. Elliot', 'Joseph H. Rainey', 'Alonzo J. Ransier', 'Robert Smalls'],
    officeholders: 316,
  },
  {
    state: 'Louisiana',
    congressmen: ['Jeremiah Haralson', 'James T. Rapier', 'Benjamin S. Turner'],
    officeholders: 173,
  },
  {
    state: 'Mississippi',
    congressmen: ['Richard H. Cain', 'Robert C. De Large', 'Robert B. Elliot', 'Joseph H. Rainey', 'Alonzo J. Ransier', 'Robert Smalls'],
    officeholders: 316,
  },
  {
    state: 'North Carolina',
    congressmen: ['Jeremiah Haralson', 'James T. Rapier', 'Benjamin S. Turner'],
    officeholders: 173,
  },
  {
    state: 'South Carolina',
    congressmen: ['Richard H. Cain', 'Robert C. De Large', 'Robert B. Elliot', 'Joseph H. Rainey', 'Alonzo J. Ransier', 'Robert Smalls'],
    officeholders: 316,
  },
  {
    state: 'Virginia',
    congressmen: ['Jeremiah Haralson', 'James T. Rapier', 'Benjamin S. Turner'],
    officeholders: 173,
  },
];

export default MapModalInfo;

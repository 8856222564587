import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import {TweenMax} from 'gsap';

import timeline from 'components/timelineComponent';
import {Image} from 'components/Image';
import FullScreenContainer from 'components/FullScreenContainer';


import {placeholder} from 'utils/animation'
import scene from 'components/scene'
import { showElemWithAria, hideElementWithAria } from 'utils/animation';
import { tabbable } from 'utils/accessibility';

import './chapter-1-scene-5.scss';

const C1S5 = class extends React.Component {
  render() {
    const {
      mlkTv,
      map,
    } = this.props.data;

    return (
      <div className="chapter-1-scene-5">
        <FullScreenContainer>
          <div className="chapter-1-scene-5__map" ref={(ref) => this.map = ref}>
            <Image image={map} sizes="100vw" alt="section background" />
          </div>
          <div className="chapter-1-scene-5__tv-bg" ref={(ref) => this.mlkTv = ref}>
            <Image image={mlkTv} sizes="100vw" alt="mlk on tv"/>
          </div>
          <div {...tabbable} className="chapter-1-scene-5__paragraph">
            <h3 ref={(ref) => this.paragraph = ref}>
              So before we examine what happened in Selma—and why it <span className="red">advanced equal voting rights.</span>
            </h3>
            <div className="subhead" ref={(ref) => this.subhead = ref}>
              Let’s take a look at the history that brought the country’s collective focus to that single time and place.
            </div>
          </div>
        </FullScreenContainer>
      </div>
    );
  }
};

const animation = ({context, timeline, easeOut}) => {

  const {map, paragraph, subhead, mlkTv} = context;
  const {innerWidth} = window;

  TweenMax.set(map, {...hideElementWithAria, y: 150});
  TweenMax.set(paragraph, {...hideElementWithAria, y: 30});
  TweenMax.set(subhead, {...hideElementWithAria, y: 30});
  TweenMax.set(mlkTv, {...hideElementWithAria, scale: 1.6, x: "-50%", y:"-50%"});

  timeline.to(mlkTv, 1.5, {scale: 0.33, ...showElemWithAria, ease: Expo.easeInOut});
  timeline.to(map, 1.5, {...showElemWithAria, y: 0, ...easeOut}, "-=0.75");
  timeline.to(paragraph, 1, {...showElemWithAria, y: 0, ...easeOut}, "-=1");
  timeline.to(subhead, 1, {...showElemWithAria, y: 0, ...easeOut},"-=0.75");

};

const query = graphql`
  query {
      mlkTv: file(relativePath: { eq: "chapter-1/scene-5/img/ch1-gag-television.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...selmaFluidImage
          }
        }
      }
      map: file(relativePath: { eq: "chapter-1/scene-5/img/ch1-map-tv@2x.jpg" }) {
        ...largeImage
      }
  }
`;

const timelineComponent = timeline(C1S5, {animation})
const Component = scene(timelineComponent);
export default (props) => {
  const data = useStaticQuery(query);
  return <Component data={data} {...props} />;
};

import React from 'react';
import PropTypes from 'prop-types';

import '../styles/components/horizontal-timeline.scss';
import timeline from './timelineComponent';
import {Power0} from 'gsap/EasePack';

const HorizontalTimeline = class extends React.Component {
  componentDidMount() {
    const {timeline, timeRange, timeRangeLabels} = this.props;
    this.checkInnerDates(timeRange, timeRangeLabels);

    timeline.to(this.handle, 1, {x: `100%`, ease: Power0.easeNone});
  }

  checkInnerDates(timeRange, timeRangeLabels) {
    const sortedArr = [...timeRange].sort((a, b) => a - b);
    if (sortedArr.toString() !== timeRange.toString()) {
      throw new Error(`timeRange must be set in ascendant order`);
    }

    if (timeRangeLabels && timeRangeLabels.length !== timeRange.length) {
      throw new Error(
          `timeRangeLabels length (${timeRangeLabels.length})
        must be the same length as timeRange's length (${timeRange.length})`
      );
    }
  }

  getDatePosition(date, timeRange) {
    const totalRange = timeRange[timeRange.length - 1] - timeRange[0];
    const realDate = date - timeRange[0];
    return (100 * realDate) / totalRange;
  }

  getCurrentDate(timeline, timeRange) {
    const totalRange = timeRange[timeRange.length - 1] - timeRange[0];
    const progress = timeline.progress();
    return Math.round(progress * totalRange) + timeRange[0];
  }

  getCurrentLabel(timeline, timeRange, timeRangeLabels) {
    const currentDate = this.getCurrentDate(timeline, timeRange);
    const rangeDate = timeRange.filter((val) => currentDate <= val)[0];
    const labelIndex = timeRange.indexOf(rangeDate);
    return timeRangeLabels[labelIndex];
  }

  hasPassedBullet(date, timeline, timeRange) {
    const currentDate = this.getCurrentDate(timeline, timeRange);
    return currentDate >= date ? 'is-white' : '';
  }

  renderInnerDates = (innerDates, timeRange) =>
    innerDates.map((element, index) => (
      <div className="timeline-date"
        key={`timeline-date-${index}`}
        style={{
          left: `${this.getDatePosition(element, timeRange)}%`,
        }}>
        <h4>{element}</h4>
      </div>
    ))

  renderInnerBullets = (innerDates, timeline, timeRange) =>
    innerDates.map((element, index) => (
      <div className={
        `timeline-bullet inner-bullet ${
          this.hasPassedBullet(element, timeline, timeRange)
        }`}
      key={`timeline-bullet-${index}`}
      style={{
        left: `${this.getDatePosition(element, timeRange)}%`,
      }}
      />
    ))

  render() {
    const {timeline, timeRange, timeRangeLabels} = this.props;
    const innerDates = timeRange.filter(
        (val, index) => index > 0 && index < timeRange.length - 1
    );
    const hasLabels = timeRangeLabels && timeRangeLabels.length;
    const firstTimeRange = timeRange[0];
    const lastTimeRange = timeRange[timeRange.length - 1];

    return (
      <div className={`horizontal-timeline${hasLabels ? ' has-labels' : ''}`}>
        <div className="horizontal-timeline__dates">
          <div className="timeline-date" key={`timeline-date-first`}>
            <h4>{firstTimeRange}</h4>
          </div>
          {
            innerDates.length && this.renderInnerDates(innerDates, timeRange)
          }
          <div className="timeline-date" key={`timeline-date-last`}>
            <h4>{lastTimeRange}</h4>
          </div>
        </div>
        <div className="horizontal-timeline__inner-content"
          ref={(ref) => this.innerTimeline = ref}>
          <div className="line">
          </div>
          <div className="bullets">
            <div className={`timeline-bullet ${
              this.hasPassedBullet(firstTimeRange, timeline, timeRange)
            }`}/>
            {
              innerDates.length && this.renderInnerBullets(innerDates, timeline, timeRange)
            }
            <div className={`timeline-bullet ${
              this.hasPassedBullet(lastTimeRange, timeline, timeRange)
            }`}/>
          </div>
          <div className="progress"
            ref={(ref) => (this.handle = ref)}>
            <div className="handle"/>
            <div className="label">
              <h4>
                {this.getCurrentDate(timeline, timeRange)}
              </h4>
              {hasLabels && (
                <div className="description caption">
                  {this.getCurrentLabel(timeline, timeRange, timeRangeLabels)}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
};

HorizontalTimeline.propTypes = {
  timeRange: PropTypes.array.isRequired,
  innerDates: PropTypes.array,
};

export default timeline(HorizontalTimeline);

import React from 'react';

import './amendment-modal.scss';

const AmendmentModal = () => (
  <div className="amendments-modal">
    <div className="amendments-modal__left">
      <p className="subhead"><span className="highlight">The U.S. Constitution and its amendments do not include a “right to vote.” </span>Instead, they prevent states or the federal government from denying citizens the right to vote based on race, gender, and age for those 18 and older.</p>
    </div>
    <div className="amendments-modal__right">
      <div className="amendments-modal__amendment">
        <h4>1865</h4>
        <h5>13th Amendment</h5>
        <p className="alt">Abolished slavery and involuntary servitude, except as punishment for a crime.</p>
      </div>
      <div className="amendments-modal__amendment">
        <h4>1868</h4>
        <h5>14th Amendment</h5>
        <p className="alt">Grants citizenship to "all persons born or naturalized in the United States" including former slaves freed after the Civil War.</p>
      </div>
      <div className="amendments-modal__amendment">
        <h4>1870</h4>
        <h5>15th Amendment</h5>
        <p className="alt">Provides that “the right of citizens of the United States to vote shall not be denied or abridged by the United States or by any state on account of race, color, or previous condition of servitude.”</p>
      </div>
    </div>
  </div>
);

export default AmendmentModal;

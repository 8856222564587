import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import ChapterPage from 'components/chapter';
import ChapterIntro from 'templates/ChapterIntro';
import ChapterConclusion from 'components/ChapterConclusion';
import Scene1 from 'components/scenes/chapter-1/scene-1/Chapter1Scene1';
import Scene2 from 'components/scenes/chapter-1/scene-2/Chapter1Scene2';
import Scene3 from 'components/scenes/chapter-1/scene-3/Chapter1Scene3';
import Scene4 from 'components/scenes/chapter-1/scene-4/Chapter1Scene4';
import Scene5 from 'components/scenes/chapter-1/scene-5/Chapter1Scene5';
import Scene6 from 'components/scenes/chapter-1/scene-6/Chapter1Scene6';
import { Chapter1Qa } from 'components/scenes/chapter-1/scene-7/Chapter1Qa';
import Timeline from 'components/timelines/pre-1973/TimelinePre1973';

import EndCardBG from '../components/scenes/chapter-1/scene-7/images/ch-1-endcard-bg@2x.jpg';

export class Chapter1 extends React.Component {

  render() {
    const {SceneComponent, controller, data} = this.props;
    const {endImage, heroImage} = data;

    const title = 'Race & Voting Rights'
    return (
      <div className="chapter-1">
        <ChapterPage
          chapterNumber={1}
          title={title}
          scenes={[
            <ChapterIntro
              title={'Chapter 1'}
              subtitle={title}
              hero={heroImage}
              alt="Demonstrators, many with signs, protest outside the White House, Washington DC, March 12, 1965. The signs reference voting rights and ongoing police brutality against Civil Rights activists in Selma, Alabama. (Photo by Warren K Leffler/PhotoQuest/Getty Images)"
            />,
            <Scene1/>,
            <Scene2 />,
            <Scene3 />,
            <Scene4 />,
            <Scene5 />,
            <Scene6 />,
            <Timeline/>,
            <ChapterConclusion
              image={EndCardBG}
              questions={Chapter1Qa}
              chapterNumber={1}
              nextChapterTitle={'Democracy Denied in Dallas County'}
              theme="dark"
            />,

          ]}
        />
      </div>
    );
  }
}

export const query = graphql`
  query {
      heroImage: file(relativePath: { eq: "chapter-1/background.jpg" }) {
        ...mediumImage
      }
      endImage: file(relativePath: { eq: "chapter-1/ch-1-endcard-bg@2x.jpg" }) {
        ...largeImage
      }
  }
`;

Chapter1.propTypes = {
  SceneComponent: PropTypes.func,
  controller: PropTypes.object,
};

export default Chapter1;
